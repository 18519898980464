import React, { useState } from 'react';
import {useQuery} from 'react-query'
import axios from 'axios'

// https://jsonplaceholder.typicode.com/posts/1/
// https://jsonplaceholder.typicode.com/posts/1/comments

{/*

// Get the user
const { data: user } = useQuery(['user', email], getUserByEmail)

const userId = user?.id

// Then get the user's projects
const { isIdle, data: projects } = useQuery(
  ['projects', userId],
  getProjectsByUser,
   {
     // The query will not execute until the userId exists
     enabled: !!userId,
   }
 )

*/}

async function fetchPost(){
	// const {data: data_post} = await axios.get('https://jsonplaceholder.typicode.com/posts/1')
	const {data} = await axios.get('https://jsonplaceholder.typicode.com/posts/1')
  return data
}

async function fetchComments(){
  // const {data: data_comments} = await axios.get('https://jsonplaceholder.typicode.com/posts/1/comments')
  const {data} = await axios.get('https://jsonplaceholder.typicode.com/posts/1/comments')
  return data
}

function DependentQueries(){

  // Get the post
  const {data: data_post, isLoading: isLoadingPost} = useQuery('post', fetchPost)
  // const { data: user } = useQuery(['user', email], getUserByEmail)
  const postId = data_post?.id
  console.log(postId, 'postId')

  // const {data: data_comments, isLoading: isLoadingComments} = useQuery('comments', fetchComments)

  // Then get the user's projects
  const { isIdle, data: data_comments, isLoading: isLoadingComments } = useQuery(
    // ['comments', postId],
    'comments',
    fetchComments,
     {
       // The query will not execute until the userId exists
       enabled: !!postId,
     }
   )

  // if (isLoadingPost) return "Loading...";
  // if (isLoadingComments) return "Loading...";

  const isLoading = isLoadingPost || isLoadingComments
  if (isLoading) return "Loading...";

  // if (error) return "An error has occurred: " + error.message;

  return (
    <div className='container'>
    	<h1>Multiple Queries</h1>

    	<h2>Post</h2>
      <h3>{data_post.title}</h3>

    	<h2>Comments</h2>
    	{
        data_comments.map((comment, index) => {
        	return (
            <React.Fragment key={index}>
							<h4>{comment.name}</h4>
             </React.Fragment>
					)
        })
    	}

    </div>
  )
}

export default DependentQueries;
