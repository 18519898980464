import React from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
// import {MEMBER_ID} from '../constants/Constants';
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES} from '../constants'

// const url = `http://localhost:8080/races/member/${MEMBER_ID}`;
// const url = `http://localhost:8080/members/${MEMBER_ID}/races-upcoming`;
const url = `${URL_MEMBERS}/${MEMBER_ID}/races-upcoming`;

async function fetchRacesListBeige(){

  console.log("updated url: ", url);
	const {data} = await axios.get(url);
  return data;
}

function ListRacesBeige(){
  const {data, error, isError, isLoading } = useQuery('list_races_beige', fetchRacesListBeige)

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
		  <>
    	{/* <h1>Races</h1> */}
      <section>
    	{
        data.map((race, index) => {

          // const race_url = `/race/?id=${race.race_id}`;
          // https://registration.24hoursoflemons.com/members/races/registration/signup_id/36505
          // const race_url = `/race-entry-edit?id=${race.fk_signup_id}`;
          const race_url = `/race-entry-edit/${race.fk_signup_id}`;

        	return (
            <React.Fragment key={index}>
              {/* Single Race Event Beige */}
              {/* Desktop Version */}
              <div className="container mb-20 py-2 bg-beige">
                {/* Team i */}
                <div className="row bg-beige px-70 py-1 d-none d-md-flex">
                  <div className="col-sm-3 col-lg-3 my-auto ">
                    <h4 className="caps font-weight-bold">
                      {race.venue}
                    </h4>
                  </div>
                  <div className="col-sm-3 col-lg-2 my-auto ">
                    <p className="text-small">
                      {race.date_start}
                    </p>
                  </div>
                  <div className="col-sm-3 col-lg-2 my-auto ">
                    <h4 className="text-small">
                      {race.team_id}
                    </h4>
                  </div>
                  <div className="col-sm-3 col-lg-1 my-auto ">
                    <h4 className="text-small">
                      {race.car_number}
                    </h4>
                  </div>
                  <div className="col-sm-3 col-lg-2 my-auto ">
                    <h4 className="text-small">
                      {race.crew_status}
                    </h4>
                  </div>
                  <div className="col-sm-3 col-lg-2 my-auto ">
                    <div className="text-end">
                      <a href={race_url} className="btn btn-outline-dark btn-ghost">Edit</a>
                    </div>
                  </div>
                </div>
                {/* /Team i */}
              </div>
              {/* /Desktop Version */}
              {/* TODO: */}
              {/* Mobile Version /*/}
            </React.Fragment>
					)
        })
    	}
    </section>
		</>
  )
}

export default ListRacesBeige;
