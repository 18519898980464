// src/components/Member.js
import React from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES, URL_API_ROOT} from '../constants'
import HeroText from '../components/HeroText';
import useStore from '../store/Store';
import useFishStore from '../store/FishStore';

// const url = `http://localhost:8080/members/${MEMBER_ID}`;
// const url = 'https://jsonplaceholder.typicode.com/posts/1';
const url = `${URL_MEMBERS}/${MEMBER_ID}`;

async function fetchMember(){
	const {data} = await axios.get(url);
  return data;
}

function RenderName({data}) {
  return (
    <>
      <div>
        <h1>{data.first_name} {data.last_name}</h1>
      </div>
    </>
  );
}

function Member() {

  // Zustand > Get Votes
  const getVotes = useStore(state => state.votes);
  const addVotes = useStore(state => state.addVotes);
  const subtractVotes = useStore(state => state.subtractVotes);

  const getFishes = useFishStore(state => state.fishes);
  const addAFish = useFishStore(state => state.addAFish);

  const {data, error, isError, isLoading, isFetching } = useQuery('member', fetchMember);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      {/* Main Container */}
      <div className="container px-0">

        <HeroText data={data} activeButton="member" />

          <p>{getVotes}</p>
          <p>{getFishes}</p>

      <button onClick={addVotes}>Cast a vote</button>
      <button onClick={subtractVotes}>Delete a vote</button>
      <button onClick={addAFish}>Add A Fish</button>

        {/* Black Box */}
        <section className style={{marginTop: '-20px'}}>
          <div className="container px-30 pb-40 px-lg-70 pb-lg-90 bg-black text-white" style={{}}>
            <hr className="white mb-60" />
            <div className="row" style={{}}>
              {/* Left Column */}
              <div className="col">
                <p>
                  <span className="text-bold">Driver Points Earned This Season:</span> 0
                </p>
                <p>
                  <span className="text-bold">Username:</span> {data.username}
                  <br />
                  <span className="text-bold">Email:</span> {data.email}
                  <br />
                  <span className="text-gray">Member since {data.signup_date}</span>
                </p>
                <p>
                  <span className="text-bold">My Lemons License</span>
                  <br />
                  <span className>(Yearly Subscription)</span>
                  <br />
                  <span className="text-bold">Expiration Date</span> {data.lemons_license_expiration}
                  <br />
                  <span className="text-gray">None</span>
                </p>
                <p>
                  <a href="#" className="btn btn-outline-white btn-ghost">Buy / Renew</a>
                </p>
              </div>
              {/* /Left Column */}
              {/* Right Column */}
              <div className="col px-40" style={{borderLeft: '1px solid white'}}>
                <p>
                  <span className="text-bold">Phone:</span> {data.phone1}
                  <br />
                  <span className="text-bold">Mobile:</span> {data.phone2}
                  <br />
                  <span className="text-bold">Address:</span> {data.address1}
                  <br />
                  <span className>{data.city}, {data.state} {data.zip}</span>
                  <br />
                  <span className="text-bold">Country:</span> {data.country}
                </p>
                <p>
                  <span className="text-bold">Emergency Contact</span>
                  <br />
                  <span className="text-bold">Name:</span> {data.emergency_first_name} {data.emergency_last_name}
                  <br />
                  <span className="text-bold">Phone:</span> {data.emergency_phone1}
                  <br />
                  <span className="text-bold">Mobile:</span> {data.emergency_phone2}
                </p>
                <p>
                  <a href="/member-edit" className="btn btn-outline-white btn-ghost">Edit Profile</a>
                </p>
              </div>
              {/* /Right Column */}
            </div>
          </div>
        </section>
        {/* /Black Box */}
      </div>
      {/* /Main Container */}

    </>
  );
}

export default Member;
