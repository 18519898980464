// src/components/Member.js
import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES, URL_SIGNUPS, URL_API_ROOT} from '../constants'
import HeroText from '../components/HeroText';
import ListRacesWhite from '../components/ListRacesWhite';
import ListRacesBeige from '../components/ListRacesBeige';
import { useParams } from 'react-router-dom';

// URLs
// const url = `http://localhost:8080/members/${MEMBER_ID}`;
// const url_member = `http://localhost:8080/members`;
// const url_signup = `http://localhost:8080/signups`;
// const url_race = `http://localhost:8080/races`;
// const url_team = `http://localhost:8080/teams`;

const url = `${URL_MEMBERS}/${MEMBER_ID}`;
const url_member = `${URL_MEMBERS}`;
const url_signup = `${URL_SIGNUPS}`;
const url_race = `${URL_RACES}`;
const url_team = `${URL_TEAMS}`;

// Fetch Queries
async function fetchMember(someId){
  const {data} = await axios.get(url_member + '/' + someId);
  return data;
}
/*
async function fetchMember(){
  const {data} = await axios.get(url);
  return data;
}
*/

async function fetchSignUp(someId){
  const {data} = await axios.get(url_signup + '/' + someId);
  return data;
}

async function fetchRace(someId){
  const {data} = await axios.get(url_race + '/' + someId);
  return data;
}

async function fetchTeam(someId){
  const {data} = await axios.get(url_team + '/' + someId);
  return data;
}

async function updateTeamInfo(thePostData) {

  console.log("Hello");
  console.log("thePostData", thePostData);
  console.log("thePostData ID", thePostData.team_id);
  const response = await axios.post(url_team + '/' + thePostData.team_id, thePostData)

}

function RaceEntryEdit() {

  const [teamName, setTeamName] = useState('');
  const [teamConcept, setTeamConcept] = useState('');
  const [teamHometown, setTeamHometown] = useState('');
  const [teamWebsite, setTeamWebsite] = useState('');

  console.log('teamName', teamName);
  console.log('teamConcept', teamConcept);

  // Get the signup id from the URL
  // NOTE: Don't forget, you can't just use some random variable here, it has to match whatever is
  // defined in react router, e.g. :id, therefore, have to use id when destructuring from useParams.
  const { id } = useParams();

  // const {data: data_member, isLoading: isLoadingMember } = useQuery(['member', MEMBER_ID], fetchMember);

  // -------------------------------
  // Signup Data
  const {data: data_signup, isLoading: isLoadingSignup } = useQuery(['signup', id], () => fetchSignUp(id));

  // -------------------------------
  // Race Data
  // Dependent query step 2: Don't run until raceId is defined, which will happen after signup is returned
  const raceId = data_signup?.[0].fk_race_id;
  const {isIdle: isIdleRace, data: data_race, isLoading: isLoadingRace } = useQuery(['race', raceId], () => fetchRace(raceId), {enabled: !!raceId});

  // -------------------------------
  // Team Data
  // Dependent query step 2: Don't run until raceId is defined, which will happen after signup is returned
  const teamId = data_signup?.[0].fk_team_id;
  // const {isIdle: isIdleTeam, data: data_team, isLoading: isLoadingTeam } = useQuery(['team', teamId], () => fetchTeam(teamId), {enabled: !!teamId});
  const {isIdle: isIdleTeam, data: data_team, isLoading: isLoadingTeam } = useQuery(['team', teamId], () => fetchTeam(teamId), {
    enabled: !!teamId,
    onSuccess: (data_team) => {
      setTeamName(data_team[0].name);
      setTeamConcept(data_team[0].concept);
      setTeamHometown(data_team[0].hometown);
      setTeamWebsite(data_team[0].website);
    }

  });

  // -------------------------------
  // Member Data
  const memberId = data_team?.[0].fk_captain_id;
  const {isIdle: isIdleMember, data: data_member, isLoading: isLoadingMember } = useQuery(['member', memberId], () => fetchMember(memberId), {enabled: !!memberId});

  // -------------------------------
  // Captain Data
  const captainId = data_team?.[0].fk_captain_id;
  const {isIdle: isIdleCaptain, data: data_captain, isLoading: isLoadingCaptain } = useQuery(['captain', captainId], () => fetchMember(captainId), {enabled: !!captainId});

  // -------------------------------
  // Save Team Info from popup
  const teamMutation = useMutation((thePostData) => updateTeamInfo(thePostData));

  // Don't display anything until all queries are finished.
  // const isLoading = isLoadingMember || isLoadingSignup || isLoadingRace;
  // const isLoading = isLoadingMember || isLoadingSignup || isLoadingRace || isLoadingTeam;
  const isLoading =  isLoadingSignup || isLoadingRace || isLoadingTeam || isLoadingMember;
  // const isLoading =  isLoadingSignup || isLoadingRace ||  isLoadingMember;
  // const isLoading =  isLoadingSignup || isLoadingTeam ||  isLoadingMember;

  if (isLoading) return "Loading...";

  return (
    <>
      {/* Main Container */}
      <div className="container px-0">

        <HeroText data={data_member[0]} />

        {/* ------------------------------- */}
        {/* Title Card */}
        <div className="container">
          {/* Banner Header */}
          <div className="container text-center py-90 ">
            <div className="row my-2">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <h1 className="text-center p-4">Manage Entry</h1>
              </div>
              {/* Location */}
                <h4 className="text-center font-weight-bold pb-1">
                  {data_race[0].venue}
                  </h4>
              {/* Dates / Deadline */}
              <div className="font-weight-bold pb-1">
                {data_race[0].date_start} - {data_race[0].date_end} / Entry Deadline: {data_race[0].entry_deadline}

              </div>
              {/* Title */}
              <div className="pb-3">
                {data_race[0].title}
              </div>
              <div className="col mt-20">
                <a href="#" className="btn btn-outline-dark btn-ghost">Save</a>
              </div>
            </div>
          </div>
          {/* /Banner Header */}
        </div>
        {/* /Title Card */}
        {/* ------------------------------- */}

        {/* ------------------------------- */}
        {/* Yellow Box */}
        <section className style={{}}>
          <div className="container px-30 pb-40 px-lg-70 py-lg-90 bg-yellow " style={{}}>
            {/* Top Section */}

            <h1 className="text-center p-4">
              {/* {data_team[0].name} */}
              {teamName}
             </h1>

            <div className="row mt-40">
              <div className="col">
                <p>
                  <span className="text-bold">Hometown:</span>  {data_team[0].hometown}
                  <br />
                  <span className="text-bold">Championship Points This Year:</span>  {data_team[0].team_points_adjustment}
                  <br />
                  <span className="text-bold">Team Concept:</span>  {data_team[0].concept}
                </p>
              </div>
              <div className="col">
                <p>
                  <span className="text-bold">Entry Status:</span>  {data_signup[0].status}
                  <br />
                  <span className="text-bold">Team Info Completed:</span>
                  <br />
                  <span className="text-bold">Website:</span>  {data_team[0].website}
                </p>
              </div>
              <div className="col">
                <p>
                  <span className="text-bold">Entry Fees Completed:</span> <span className="text-red text-uppercase text-bold">No</span>
                  <br />
                  <span className="text-bold">This Entry Is:</span> <span className="text-red text-uppercase text-bold">Not Ready</span>
                  <br />
                  <span className="text-bold">Team Captain:</span> {data_captain[0].first_name} {data_captain[0].last_name}
                </p>
              </div>
            </div>
            <div className="row mt-20 mb-40">
              <div className="col text-center">
                <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-are-you-sure">Withdraw Entry</a>
                <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-edit-team-info">Edit</a>
              </div>
            </div>
            {/* /Top Section */}
            {/* Popup > Edit Team Info */}
            {/* Button trigger modal */}
            {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
            {/* Button trigger modal */}
            {/* Modal */}
            <div className="modal" id="popup-edit-team-info" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                  {/* Modal Header */}
                  <div className="modal-header">
                    <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                    </a>
                  </div>
                  {/* /Modal Header */}
                  {/* Modal Body */}
                  <div className="modal-body">
                    <div className="container px-60">
                      <div className="row mb-20">
                        <div className="col">
                          <h2 className="modal-title" id="popup-edit-team-info-label">Team Info</h2>
                        </div>
                      </div>
                      <div className="row mb-20 text-small">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="first_last_name" className="text-bold text-small mb-2">Team Name*</label>
                            <input type="email" className="form-control" value={teamName} onChange={e => setTeamName(e.target.value)} id="first_last_name" aria-describedby="emailHelp" placeholder />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-20 text-small">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="first_last_name" className="text-bold text-small mb-2">Team Concept</label>
                            <textarea className="form-control text-small" id="exampleFormControlTextarea1" rows={3} value={teamConcept} onChange={e => setTeamConcept(e.target.value)} />
                          </div>
                          <p className="mt-10">
                            Give us a reason to want you: ridiculous car, funny theme, dorky costumInevsit,eweird history, whatever.
                          </p>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="first_last_name" className="text-bold text-small mb-2">Hometown*</label>
                            <input type="email" className="form-control" value={teamHometown} onChange={e => setTeamHometown(e.target.value)}  id="first_last_name" aria-describedby="emailHelp" placeholder />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="first_last_name" className="text-bold text-small mb-2">Website</label>
                            <input type="email" className="form-control" value={teamWebsite} onChange={e => setTeamWebsite(e.target.value)} value={data_team[0].website}  id="first_last_name" aria-describedby="emailHelp" placeholder />
                          </div>
                          <div className="form-group mt-20">
                            <input className="form-check-input me-10" type="checkbox" defaultValue id="test-checkbox" />
                            <label htmlFor="test-checkbox" className="text-bold text-small mb-2">Check here if you want fans to see your info</label>
                          </div>
                          <div className="form-group mt-20">
                            <p className="text-small">
                              This site protected by reCAPCHA. The Google <a href="#" className="text-green-dark">Privacy Policy</a> and <a href="#" className="text-green-dark">Terms of Serivce</a> apply.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">

                          <a href="#" className="btn btn-outline-dark btn-ghost"
	                          onClick={() => {
	                            // mutation.mutate({ name: 'James Hong', email: 'james@example.com', phone: '555-555-5555' })
                              teamMutation.mutate({
                                team_id: data_team[0].team_id,
                                name: teamName,
                                concept: teamConcept,
                                hometown: teamHometown,
                                website: teamWebsite,
                              })
	                          }}

                          >Save</a>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Modal Body */}
                </div>
              </div>
            </div>
            {/* /Modal */}
            {/* /Popup > Edit Team Info */}
            {/* Popup > Edit Team Info */}
            {/* Button trigger modal */}
            {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
            {/* Button trigger modal */}
            {/* Modal */}
            <div className="modal" id="popup-are-you-sure" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                  {/* Modal Header */}
                  <div className="modal-header">
                    <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                    </a>
                  </div>
                  {/* /Modal Header */}
                  {/* Modal Body */}
                  <div className="modal-body">
                    <div className="container px-60">
                      <div className="row mb-20">
                        <div className="col">
                          <h2 className="modal-title lh-1-2" id="popup-am-i-driver-label">Are you sure you want to permanently withdraw this entry?</h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <a href="#" className="btn btn-outline-dark btn-ghost">Yes</a>
                          <a href="#" className="btn btn-outline-dark btn-ghost">Hells No</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Modal Body */}
                </div>
              </div>
            </div>
            {/* /Modal */}
            {/* /Popup > Edit Team Info */}
            <hr className="black mb-60" />
            <div className="row" style={{}}>
              {/* Left Column */}
              <div className="col pe-20">
                <h2 className="mb-40">Roster</h2>
                {/* Captain */}
                <div className="row">
                  <div className="col-7">
                    <h3>{data_captain[0].first_name} {data_captain[0].last_name} <sup><span className="text-small text-capitalize">(Captain)</span></sup></h3>
                    <p>
                      <span className="text-bold">Race License:</span> <span className="text-uppercase text-bold">Ok</span>
                    </p>
                    <div className="form-group mt-20 mb-20">
                      <div className="row">
                        <div className="col">
                          <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                          <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Driver</label>
                        </div>
                        <div className="col">
                          <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio2" />
                          <label htmlFor="crew" className="text-bold text-uppercase mb-1 mt-2">Crew</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <a href="#" className="btn btn-outline-dark btn-ghost">Remove</a>
                    <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-edit-team-member">Edit</a>
                  </div>
                </div>
                <hr className="black mb-20" />
                {/* /Captain */}
                {/* Popup > Edit Team Member */}
                {/* Button trigger modal */}
                {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
                {/* Button trigger modal */}
                {/* Modal */}
                <div className="modal" id="popup-edit-team-member" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                    <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                      {/* Modal Header */}
                      <div className="modal-header">
                        <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                        </a>
                      </div>
                      {/* /Modal Header */}
                      {/* Modal Body */}
                      <div className="modal-body">
                        <div className="container px-60">
                          <div className="row mb-20">
                            <div className="col">
                              <h2 className="modal-title" id="popup-edit-team-member-label">Edit</h2>
                            </div>
                          </div>
                          <div className="row mb-20 text-small">
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="first_last_name" className="text-bold text-small mb-2">Name*</label>
                                <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-20 text-small">
                            <div className="col">
                              <div className="row">
                                <div className="col-4">
                                  <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                                  <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Driver</label>
                                </div>
                                <div className="col-4">
                                  <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio2" />
                                  <label htmlFor="crew" className="text-bold text-uppercase mb-1 mt-2">Crew</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-20">
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="first_last_name" className="text-bold text-small mb-2">Race License*</label>
                                <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col text-center">
                              <a href="#" className="btn btn-outline-dark btn-ghost">Save</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /Modal Body */}
                    </div>
                  </div>
                </div>
                {/* /Modal */}
                {/* /Popup > Edit Team Member */}

                {/* Driver */}
                {/*
                <div className="row">
                  <div className="col-7">
                    <h3>John "Jay" Lamm</h3>
                    <p>
                      <span className="text-bold">Race License:</span> <span className="text-red text-uppercase text-bold">Not Ok</span>
                    </p>
                    <div className="form-group mt-20 mb-20">
                      <div className="row">
                        <div className="col">
                          <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                          <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Driver</label>
                        </div>
                        <div className="col">
                          <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio2" />
                          <label htmlFor="crew" className="text-bold text-uppercase mb-1 mt-2">Crew</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <a href="#" className="btn btn-outline-dark btn-ghost">Remove</a>
                  </div>
                </div>
                <hr className="black mb-10" />
                  */}
                {/* /Driver */}

                {/* Driver/Crew 3 */}
                <div className="row align-items-center">
                  <div className="col-7">
                    <h3>Driver/Crew 3</h3>
                  </div>
                  <div className="col-5">
                    <a href="#" className="btn btn-outline-dark btn-ghost">Invite</a>
                  </div>
                </div>
                <hr className="black mb-10" />
                {/* Driver/Crew 3 */}
                {/* Driver/Crew 4 */}
                <div className="row align-items-center">
                  <div className="col-7 ">
                    <h3>Driver/Crew 4</h3>
                  </div>
                  <div className="col-5 ">
                    <a href="#" className="btn btn-outline-dark btn-ghost">Invite</a>
                  </div>
                </div>
                <hr className="black mb-10" />
                {/* Driver/Crew 4 */}
                {/* Add More Idiots */}
                <div className="row align-items-center">
                  <div className="col-7 ">
                    <h3>Add <span className="text-decoration-underline">More</span> Idiots</h3>
                  </div>
                  <div className="col-5 ">
                    <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-add-team-member">Add Team Member</a>
                  </div>
                </div>
                {/* /Add More Idiots */}
                {/* Popup > Add Team Member */}
                {/* Button trigger modal */}
                {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
                {/* Button trigger modal */}
                {/* Modal */}
                <div className="modal" id="popup-add-team-member" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                    <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                      {/* Modal Header */}
                      <div className="modal-header">
                        <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                        </a>
                      </div>
                      {/* /Modal Header */}
                      {/* Modal Body */}
                      <div className="modal-body">
                        <div className="container px-60">
                          <div className="row mb-20">
                            <div className="col">
                              <h2 className="modal-title" id="popup-add-team-member-label">Add a Team Member</h2>
                            </div>
                          </div>
                          <div className="row mb-60 text-small">
                            <div className="col">
                              <p>
                                Your invitees will be emailed this unique Invite Code. Want your own custom code? Just enter it here and click SET before sending your invites.
                              </p>
                            </div>
                          </div>
                          <div className="row mb-20">
                            <div className="col">
                              <h2 className="modal-title lh-1-2" id="popup-add-team-member-label">There are Four Ways to Invite Team Members</h2>
                            </div>
                          </div>
                          <div className="row mb-40">
                            <div className="col">
                              <a href="#" className="btn btn-outline-dark btn-ghost w-100">Invite Previous Team Members</a>
                              <a href="#" className="btn btn-outline-dark btn-ghost w-100">Invite New Team Members Via Email</a>
                              <a href="#" className="btn btn-outline-dark btn-ghost w-100">Invite New Team Members from Database</a>
                              <a href="#" className="btn btn-outline-dark btn-ghost w-100">Browse for Wannabes Seeking a Ride</a>
                            </div>
                          </div>
                          <div className="row text-small">
                            <div className="col">
                              <p>
                                If your invitee is email-incompetent, copy this link and give it to them directly—it goes straight into the reg process for your entry:
                              </p>
                              <p>
                                https://registration.24hoursoflemons.com/members/index/join-race-by-passcode?passcode=N7A6TCWD
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /Modal Body */}
                    </div>
                  </div>
                </div>
                {/* /Modal */}
                {/* /Popup > Add Team Member */}
                {/* Driver, Crew or Spectator */}
                <div className="row align-items-center mt-20">
                  <div className="col-7 ">
                    <h3>Driver, Crew or Spectator?</h3>
                  </div>
                  <div className="col-5 ">
                    <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-am-i-driver">Find Out</a>
                  </div>
                </div>
                {/* /Driver, Crew or Spectator */}
                {/* Popup > Add Team Member */}
                {/* Button trigger modal */}
                {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
                {/* Button trigger modal */}
                {/* Modal */}
                <div className="modal" id="popup-am-i-driver" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                    <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                      {/* Modal Header */}
                      <div className="modal-header">
                        <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                        </a>
                      </div>
                      {/* /Modal Header */}
                      {/* Modal Body */}
                      <div className="modal-body">
                        <div className="container px-60">
                          <div className="row mb-20">
                            <div className="col">
                              <h2 className="modal-title lh-1-2" id="popup-am-i-driver-label">Am I Driver, Crew, or Spectator?</h2>
                            </div>
                          </div>
                          <div className="row mb-20 text-small">
                            <div className="col">
                              <ul className="list-style-type-dash text-small">
                                <li className="text-small">Pre-registered DRIVERS can work on the car and drive on track.</li>
                                <li className="text-small">Pre-registered CREW can work on the car but can not drive on track.</li>
                                <li className="text-small">Everyone else (such as SPECTATORS) can’t touch the car at all.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /Modal Body */}
                    </div>
                  </div>
                </div>
                {/* /Modal */}
                {/* /Popup > Add Team Member */}
                {/* Bring Your Mom */}
                <div className="row align-items-center mt-20">
                  <div className="col-7 ">
                    <h3>Bring Your Mom</h3>
                  </div>
                  <div className="col-5 ">
                    <a href="#" className="btn btn-outline-dark btn-ghost">Spectator Tickets</a>
                  </div>
                </div>
                {/* /Bring Your Mom */}
                {/* Invite Code */}
                <div className="row mt-20">
                  <div className="col">
                    <h3>Invite Code</h3>
                    <p>
                      If all else fails, just forward this code to your new person.
                    </p>
                    <div className="input-group mb-3 ">
                      <input type="text" className="form-control" placeholder="N7A6TCWD" aria-label="Your email address" aria-describedby="basic-addon2" defaultValue="N7A6TCWD" />
                      <div className="input-group-append">
                        <button className="btn btn-dark btn-square--large" type="button">
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Invite Code */}
              </div>
              {/* /Left Column */}
              {/* Right Column */}
              <div className="col px-40" style={{borderLeft: '1px solid black'}}>
                <h2 className="mb-40">Entry Info</h2>
                <p>
                  <span className="text-bold">Car Number:</span> {data_signup[0].car_number}
                  <br />
                  <span className="text-bold">Car Year:</span> {data_signup[0].car_year}
                  <br />
                  <span className="text-bold">Car Make:</span> {data_signup[0].car_make}
                  <br />
                  <span className="text-bold">Car Model:</span> {data_signup[0].car_model}
                  <br />
                  <span className="text-bold">Camping:</span> {data_signup[0].car_model}
                  <br />
                  <span className="text-bold">Rent Transponder:</span> {data_signup[0].rent_transponder}
                  <br />
                  <span className="text-bold">Garage:</span> {data_signup[0].additional_cost}
                  <br />
                  <span className="text-bold">Test Day:</span> {data_signup[0].additional_cost2}
                  <br />
                  <span className="text-bold">Shiatsu Massage:</span> {data_signup[0].additional_cost3}
                </p>
                <p>
                  <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-edit-entry-info">Edit</a>
                </p>
                <hr className="black my-40" />
                <h2 className="mb-40">Fees</h2>
                <p>
                  <span className="text-bold">Team:</span> $1550
                  <br />
                  <span className="text-bold">Camping:</span> (Yes) $50
                  <br />
                  <span className="text-bold">Rent Transponder:</span> (No)
                  <br />
                  <span className="text-bold">Garage:</span> (No)
                  <br />
                  <span className="text-bold">Test Day:</span> (No)
                  <br />
                  <span className="text-bold">Shiatsu Massage:</span> (Yes) $50
                </p><hr className="black my-20 w-50 border border-dark border-1" />
                <span className="text-bold">Total: $1650</span>
                <p />
                <p className="mt-30 mb-40">
                  <span className="text-bold">Total Fees Received:</span> $50
                  <br />
                  <span className="text-bold">Balance Due:</span> <span className="text-red text-bold">$1600</span>
                </p>
                <p>
                  <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-pay-now">Pay Now</a>
                  <a href="#" className="btn btn-outline-dark btn-ghost">Pay By xx/xx/xx</a>
                </p>
              </div>
              {/* /Right Column */}
              {/* Popup > Add Team Member */}
              {/* Button trigger modal */}
              {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
              {/* Button trigger modal */}
              {/* Modal */}
              <div className="modal" id="popup-edit-entry-info" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                  <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                    {/* Modal Header */}
                    <div className="modal-header">
                      <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                      </a>
                    </div>
                    {/* /Modal Header */}
                    {/* Modal Body */}
                    <div className="modal-body">
                      <div className="container px-60">
                        <div className="row mb-20">
                          <div className="col">
                            <h2 className="modal-title" id="popup-edit-team-member-label">Entry Info</h2>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <p className="mt-10">
                              Just do as much as you can. Some of these can be finished off later.
                            </p>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Car Number*</label>
                              <select className="form-select" aria-label="Default select example">
                                <option selected />
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Car Year</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Car Make</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Car Model</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="car-upload-image-00" className="text-bold text-small mb-2">Car Image</label>
                              <input type="file" id="car-upload-image" className="btn-ghost-upload" />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col-6">
                            <span className="text-bold text-small mb-2">Camping $50</span>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Yes</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col-6">
                            <span className="text-bold text-small mb-2">Camping $50</span>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Yes</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col-6">
                            <span className="text-bold text-small mb-2">Camping $50</span>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Yes</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col-6">
                            <span className="text-bold text-small mb-2">Camping $50</span>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Yes</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col-6">
                            <span className="text-bold text-small mb-2">Camping $50</span>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">Yes</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input className="form-check-input me-10" type="radio" name="test-radio" id="test-radio1" defaultChecked />
                              <label htmlFor="driver" className="text-bold text-uppercase mb-1 mt-2">No</label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20">
                          <div className="col">
                            <div className="form-group mt-20">
                              <p className="text-small">
                                This site protected by reCAPCHA. The Google <a href="#" className="text-green-dark">Privacy Policy</a> and <a href="#" className="text-green-dark">Terms of Serivce</a> apply.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-center">
                            <a href="#" className="btn btn-outline-dark btn-ghost">Save</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Modal Body */}
                  </div>
                </div>
              </div>
              {/* /Modal */}
              {/* /Popup > Add Team Member */}
              {/* Popup > Add Team Member */}
              {/* Button trigger modal */}
              {/*
<button type="button" class="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#editEvent">
  Edit Event
</button>
*/}
              {/* Button trigger modal */}
              {/* Modal */}
              <div className="modal" id="popup-pay-now" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                  <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                    {/* Modal Header */}
                    <div className="modal-header">
                      <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                      </a>
                    </div>
                    {/* /Modal Header */}
                    {/* Modal Body */}
                    <div className="modal-body">
                      <div className="container px-60">
                        <div className="row mb-20">
                          <div className="col">
                            <h2 className="modal-title" id="popup-edit-team-member-label">Pay Now</h2>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <p className="mt-10">
                              <span className="text-bold">Total Fees Received:</span> $50
                              <br />
                              <span className="text-bold">Balance Due:</span> <span className="text-red text-bold">$1600</span>
                            </p>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Payment Amount*</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Card Type*</label>
                              <select className="form-select" aria-label="Default select example">
                                <option selected />
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Card Number*</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Expires*</label>
                              <select className="form-select" aria-label="Default select example">
                                <option selected />
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2" />
                              <select className="form-select" aria-label="Default select example">
                                <option selected />
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Card Code*</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Name on Card*</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20 text-small">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Zip*</label>
                              <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-20">
                          <div className="col">
                            <div className="form-group mt-20">
                              <p className="text-small">
                                By clicking Pay Now, you agree to purchase your item(s) from XXXX as merchant of record for this transaction, on XXXX’s Terms and Conditions and Privacy Policy. XXXX is an international fulfillment service provider to 24 Hours of Lemons.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-center">
                            <a href="#" className="btn btn-outline-dark btn-ghost">Save</a>
                          </div>
                        </div>
                        <div className="row mb-20">
                          <div className="col">
                            <div className="form-group mt-20">
                              <p className="text-small text-red">
                                Processing may take a few minutes. Only click PAY NOW once, or your card may be charged multiple times.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Modal Body */}
                  </div>
                </div>
              </div>
              {/* /Modal */}
              {/* /Popup > Add Team Member */}
            </div>
          </div>
        </section>
        {/* /Yellow Box */}
        {/* ------------------------------- */}

        {/* ------------------------------- */}
        {/* Events You've Entered */}
        <section className="mb-20">
          <div className="container px-30 py-10 px-lg-70 py-lg-20 bg-yellow">
            <div className="row">
              <div className="col text-uppercase text-bold text-center">
                Events You've Entered
              </div>
            </div>
          </div>
        </section>
        {/* /Events You've Entered */}
        {/* ------------------------------- */}

        {/* ------------------------------- */}
        {/* Table Header Black*/}
        <section>
          <div className="container mb-20">
            {/* ------------------------------- */}
            {/* Desktop Version */}
            <div className="row bg-black px-70 py-20 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Location</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Date</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Team</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Car</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Status</h4>
              </div>
            </div>
            {/* /Desktop Version */}
            {/* ------------------------------- */}

            {/* ------------------------------- */}
            {/* TODO: */}
            {/* Mobile Version */}
            <div className="row bg-black px-30 py-20 d-md-none">
              <div className="col-6 my-auto">
                <h4 className="text-white text-uppercase font-weight-bold">Location</h4>
              </div>
              <div className="col-6 my-auto">
                <h4 className="text-white text-uppercase font-weight-bold">Event/Date</h4>
              </div>
            </div>
            {/* /Mobile Version */}
            {/* ------------------------------- */}

          </div>
        </section>
        {/* /Table Header Black*/}
        {/* ------------------------------- */}

        <ListRacesBeige />

        {/* ------------------------------- */}
        {/* Other Events You Might Want to Enter */}
        <section className="mb-20">
          <div className="container px-30 py-10 px-lg-70 py-lg-20 bg-black">
            <div className="row">
              <div className="col text-uppercase text-bold text-center text-white">
                Other Events You Might Want to Enter
              </div>
            </div>
          </div>
        </section>
        {/* Other Events You Might Want to Enter */}
        {/* ------------------------------- */}

        <ListRacesWhite />

      </div>
      {/* /Main Container */}
      {/* ------------------------------- */}

    </>
  );
}

export default RaceEntryEdit;
