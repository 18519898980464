import React from 'react';

export default function StaticLogin() {
    return (
    <>

      {/* This is important, make the main container always be px-0 */}
      {/* Main Container */}
      <div className="container px-0">
        {/* Black Header */}
        {/* Hero Text */}
        {/* Hero Image */}
        <section className="d-none d-md-flex mb-20 ">
          <div className="container  bg-black py-90">
            <div className="row">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                <h1 className="text-center text-yellow">Log In</h1>
              </div>
            </div>
            <div className="row text-center">
              <div className="col mt-20">
              </div>
            </div>
          </div>
        </section>
        {/* /Hero Image */}
        {/* Hero Image > Mobile Version */}
        {/*
<section class="d-md-none">
<div class="container">
  <div class="row">
    <div class="col-sm p-0" id="banner-image"
    style='background: url("/wp-content/themes/lemons/assets/images/") no-repeat center center; width: 100%; min-height: 248px; height: 100%; background-size: cover;'>
    </div>
  </div>
</div>
</section>
*/}
        {/* /Hero Image > Mobile Version */}
        {/* /Hero Text */}
        {/* /Black Header */}
        {/* Yellow Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-yellow mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h2>Log In</h2>

                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Email*</label>
                  <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                </div>

                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Password*</label>
                  <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                </div>

              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3">
                <a href className="btn btn-outline-dark btn-ghost">Log In</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Yellow Box */}

      </div>
      {/* /Main Container */}
      </>
    );
  }
