import { BrowserRouter as Router, Link, Route, Routes, useParams } from 'react-router-dom';

// Tests
import Posts from './pages/Posts';
import MultipleQueries from './pages/MultipleQueries';
import UserAdd from './pages/UserAdd';
import UserEdit from './pages/UserEdit';
import DependentQueries from './pages/DependentQueries';
import SinglePost from './components/SinglePost';
import SinglePost2 from './components/SinglePost2';

// Pages
import Home from './pages/Home';
import Member from './pages/Member';
import MemberEdit from './pages/MemberEdit';
import RaceEntryEdit from './pages/RaceEntryEdit';
import RaceEntryAdd from './pages/RaceEntryAdd';
import TeamInfoEdit from './pages/TeamInfoEdit';
import Test from './pages/Test';
import Test1 from './pages/Test1';
import Test2 from './pages/Test2';
import CreateEntry from './pages/CreateEntry';
import Login from './pages/Login';

// Static Versions
import StaticHome from './static/StaticHome';
import StaticRegistration from './static/StaticRegistration';
import StaticRaceEntryAdd from './static/StaticRaceEntryAdd';
import StaticRaceEntryAddForm from './static/StaticRaceEntryAddForm';
import StaticRaceEntryEdit from './static/StaticRaceEntryEdit';
import StaticMemberEdit from './static/StaticMemberEdit';
import StaticLogin from './static/StaticLogin';

// Components
import ListRacesWhite from './components/ListRacesWhite';
import ListRacesBeige from './components/ListRacesBeige';

function App() {
  return (
    <div className="App">

      {/* <SinglePost /> */}
      {/* <hr /> */}
      {/* <SinglePost2 /> */}

      {/* <Posts /> */}
      {/* <Member /> */}

      <Router>

        <nav style={{ position: 'fixed', top: '50px', left: '50px', display: 'block', background: 'rgba(255, 255, 255, 0.8)', padding: '20px 20px 20px 5px', border: '1px solid gray', zIndex: '1000', fontSize: '12px', lineHeight: '1.5' }}>
          <ul>
            {/* <li><Link to="/">Home</Link></li> */}
            <li><Link to="/test">Test</Link></li>
            <li><Link to="/test1">Test 1</Link></li>
            <li><Link to="/test2">Test 2</Link></li>
            <li><Link to="/create-entry">Create Entry</Link></li>
            <li><Link to="/static-login">Static Login</Link></li>
            <li><Link to="/static-home">Static Home</Link></li>
            <li><Link to="/static-registration">Static Registration</Link></li>
            <li><Link to="/static-race-entry-add">Static Race Entry Add</Link></li>
            <li><Link to="/static-race-entry-add-form">Static Race Entry Add Form</Link></li>
            <li><Link to="/static-race-entry-edit">Static Race Entry Edit</Link></li>
            <li><Link to="/static-member-edit">Static Member Edit</Link></li>

            <li><Link to="/login">Login</Link></li>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/member">Member</Link></li>
            <li><Link to="/member-edit">Member Edit</Link></li>
            <li><Link to="/race-entry-edit">Race Entry Edit</Link></li>
            <li><Link to="/team-info-edit">Team Info Edit</Link></li>

            <li><Link to="/members/races/pick-team/race_id/338">Enter Race 338</Link></li>

            <li><Link to="/list-races-white">List Races White</Link></li>
            <li><Link to="/list-races-beige">List Races Beige</Link></li>

            <li><Link to="/posts">Posts</Link></li>
            <li><Link to="/multiple-queries">Multiple Queries</Link></li>
            <li><Link to="/dependent-queries">Dependent Queries</Link></li>
            <li><Link to="/user-add">User Add</Link></li>
            <li><Link to="/user-edit">User Edit</Link></li>
            {/* <li><Link to="/teams">Teams</Link></li> */}
            {/* <li><Link to="/races">Races</Link></li> */}
          </ul>
        </nav>

        <Routes>
          {/* <Route path="/" /> */}

          <Route path="test" element={<Test />} />
          <Route path="test1" element={<Test1 />} />
          <Route path="test2" element={<Test2 />} />
          <Route path="create-entry" element={<CreateEntry />} />
          <Route path="login" element={<Login />} />
          <Route path="home" element={<Home />} />
          <Route path="member" element={<Member />} />
          <Route path="member-edit" element={<MemberEdit />} />
          <Route path="race-entry-edit/:id" element={<RaceEntryEdit />} />
          <Route path="team-info-edit/:id" element={<TeamInfoEdit />} />

          <Route path="/members/races/pick-team/race_id/:id" element={<RaceEntryAdd />} />

          <Route path="static-login" element={<StaticLogin />} />
          <Route path="static-home" element={<StaticHome />} />
          <Route path="static-registration" element={<StaticRegistration />} />
          <Route path="static-race-entry-add" element={<StaticRaceEntryAdd />} />
          <Route path="static-race-entry-add-form" element={<StaticRaceEntryAddForm />} />
          <Route path="static-race-entry-edit" element={<StaticRaceEntryEdit />} />
          <Route path="static-member-edit" element={<StaticMemberEdit />} />

          <Route path="list-races-white" element={<ListRacesWhite />} />
          <Route path="list-races-beige" element={<ListRacesBeige />} />

          <Route path="posts" element={<Posts />} />
          <Route path="multiple-queries" element={<MultipleQueries />} />
          <Route path="dependent-queries" element={<DependentQueries />} />
          <Route path="user-add" element={<UserAdd />} />
          <Route path="user-edit" element={<UserEdit />} />
          {/* <Route path="teams" element={<Teams />} /> */}
          {/* <Route path="races" element={<Races />} /> */}
        </Routes>

      </Router>

    </div>
  );
}

export default App;
