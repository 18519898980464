import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants';

// Get My Teams
export const getMyTeams2 = async (member_id) => {
  const {data} = await axios.get(URL_MEMBERS + '/' + member_id + '/teams');
  return data;
};

// Get All Teams
export const getAllTeams = async () => {
  const {data} = await axios.get(URL_TEAMS);
  return data;
};

// Get Specific Team
export const geTeam2 = async (team_id) => {
  const {data} = await axios.get(URL_TEAMS + '/' + team_id);
  return data;
};

// Create Team
export const createTeam = async ({ ...data }) => {
  console.log("createTeam data: ", data)
  const response = await axios.post(URL_TEAMS + '/', data)
}
