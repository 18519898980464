import React from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES, URL_API_ROOT} from '../constants'

// const url = `http://localhost:8080/races/member/${MEMBER_ID}`;
const url = `${URL_API_ROOT}/races-upcoming`;

async function fetchRacesListWhite(){
	const {data} = await axios.get(url);
  return data;
}

function ListRacesWhite(){
  const {data, error, isError, isLoading } = useQuery('list_races_white', fetchRacesListWhite)

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
		  <>
    	{/* <h1>Races</h1> */}
      <section>
    	{
        data.map((race, index) => {

          const race_url = `/race/?id=${race.race_id}`;

        	return (
            <React.Fragment key={index}>

              {/* Desktop Version */}
              <div className="container mb-20 d-none d-md-flex">
                <div className="row px-70 py-30 border border-dark border-4">
                  <div className="col-sm my-auto">
                    <h4 className="caps font-weight-bold">{race.venue}</h4>
                  </div>
                  <div className="col-sm my-auto">
                    <p>
                      {race.date_start}
                    </p>
                  </div>
                  <div className="col-sm my-auto">
                    <h4 className="font-weight-bold">{race.title}</h4>
                  </div>
                  <div className="col-sm my-auto">
                    <div className="text-end">
                      <a href={race_url} className="btn btn-outline-dark btn-ghost">Race Info</a>
                      <a href="https://registration.24hoursoflemons.com/login" className="btn btn-outline-dark btn-ghost">Register</a>
                      <a href="/spectator" className="btn btn-outline-dark btn-ghost">Spectator Tickets</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Desktop Version */}
              {/* TODO: */}
              {/* Mobile Version /*/}
            </React.Fragment>
					)
        })
    	}
    </section>
		</>
  )
}

export default ListRacesWhite;
