import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { getMyTeams2, createTeam } from "../services/api"

import { TeamForm2 } from "../components/TeamForm2"
import { TeamInfo } from "../components/TeamInfo"
import { EventInfo } from "../components/EventInfo"
import { FeeInfo } from "../components/FeeInfo"

import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants'

function CreateEntry(){

  const handleChange = (newValue) => {
    console.log("This is from the child component: ", newValue)
  }

  return (
    <div className="container px-0">

      <TeamInfo
        member_id={MEMBER_ID}
        onChange={handleChange}
      />

      <EventInfo />
      <FeeInfo />

    </div>
  )
}

export default CreateEntry
