import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { getMyTeams2, createTeam } from "../services/api"

import { TeamForm2 } from "../components/TeamForm2"

import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants'

function Test1(){

  // Add the member ID to the default form values when creating a new team
  // NOTE: Other options: https://react-hook-form.com/api/useform/
  const data = {
    fk_captain_id: MEMBER_ID,
  }

  const {isIdle: isIdleMyTeams, data: data_my_teams, isLoading: isLoadingMyTeams } = useQuery(['my_teams', MEMBER_ID], () => getMyTeams2(MEMBER_ID))

  if (data_my_teams) {
    const team_1 = data_my_teams.find( ({ team_id }) => team_id === 13275 )
    const team_2 = data_my_teams.find( ({ team_id }) => team_id === 13060 )
    console.log(team_2)
    console.log(team_1)
  }

  const handleClick = (event) => {
    // console.log(event.target.value)
    // console.log(event.target.getAttribute("value"))
    // console.log(event.target.getAttribute("data-team-id"))

    // NOTE: Nice way to get data attributes from target element
    // https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes
    console.log(event.target.dataset.teamId)
    console.log(event.target.dataset.teamName)
    // console.log(crap.dataset("data-team-id"))
    // console.log(event.target.dataset("data-team-id"))
  }

  const { mutateAsync, isLoading } = useMutation(createTeam)

  const onFormSubmit = async (data) => {
    await mutateAsync({...data})
    // navigate("/")
  }

  if (isLoadingMyTeams) return "Loading..."

  return (
    <div className='container'>
      <section>
        <div className="bg-beige p-50">

    	    <h1>Test 1</h1>
          {MEMBER_ID}

          <p>
            This will get all my teams.
          </p>

            {data_my_teams.map((team, index) => (
              <React.Fragment key={index}>
                <div>
                  <a data-team-id={team.team_id} data-team-name={team.name} onClick={handleClick}>{team.name}</a>
                </div>
              </React.Fragment>
            ))}

            <TeamForm2 defaultValues={data} onFormSubmit={onFormSubmit} isLoading={isLoading}/>
            {/* <TeamForm2 onFormSubmit={onFormSubmit} isLoading={isLoading}/> */}

        </div>
      </section>
    </div>
  )
}

export default Test1
