import React from 'react';
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";

import {URL_TEAMS} from '../constants/Constants';

// React Query > Actually post the data
async function updateTeamInfo(thePostData, mode, team_id) {

  console.log("Component Mode:", mode);
  console.log("The post id:",thePostData.team_id);

  // Updating a record
  if (mode == "edit") {
    const response = await axios.post(URL_TEAMS + '/' + team_id, thePostData)
  }

  // Inserting a new record
  if (mode == "new") {
    const response = await axios.post(URL_TEAMS + '/', thePostData)
  }
}

export default function TeamInfoForm({name, concept, hometown, website, public_profile, team_id, member_id, mode, onClick}) {

  // React Query > Set up for updates
  const teamMutation = useMutation((thePostData) => updateTeamInfo(thePostData, mode, team_id));

  // React Hook Form > Set up
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
    } = useForm({
      mode: 'all'
    });

  // React Hook Form > Handle the submitted data
  const handleSubmitData = data => {
    console.log("-------------------------------");
    console.log(data);
    console.log(team_id);
    console.log("-------------------------------");

    // React Query > Prep the data for posting
    teamMutation.mutate({
      team_id: team_id,
      fk_captain_id: data.fk_captain_id,
      name: data.name,
      concept: data.concept,
      hometown: data.hometown,
      website: data.website,
      public_profile: data.public_profile,
    })
  };

  return (
    <>

      {/* Right Column */}

        {/* <h2>{mode}</h2> */}
        {/* <h2>{team_id}</h2> */}

        <form onSubmit={handleSubmit(handleSubmitData)}>

        <div className="mb-20">
          <p className="text-small">
            Enter/confirm your team info below. And don’t stint on the Team Concept-the better it is, the more likely you are to get in.
          </p>
        </div>

        <div className="row mb-20 text-small">
          <div className="col">
            <div className="form-group">
              <label htmlFor="name" className="text-bold text-small mb-2">Team Name*</label>

              <input {...register("name", { required: true, minLength: 2 })} defaultValue={name} className="form-control"  aria-describedby="" placeholder />
              {errors?.name?.type === "required" && <p className="text-small text-red">This field is required</p>}

            </div>
          </div>
        </div>

        <div className="row mb-20 text-small">
          <div className="col">
            <div className="form-group">
              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Team Concept</label>

              <textarea {...register("concept", { required: true, minLength: 2 })} defaultValue={concept} className="form-control" rows={3}  />
              {errors?.concept?.type === "required" && <p className="text-small text-red">This field is required</p>}

            </div>
            <p className="mt-10">
              Give us a reason to want you: ridiculous car, funny theme, dorky costumInevsit,eweird history, whatever.
            </p>
          </div>
        </div>

        <div className="row mb-20">
          <div className="col">
            <div className="form-group">
              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Hometown*</label>

              <input {...register("hometown", { required: true, minLength: 2 })} defaultValue={hometown} className="form-control"  aria-describedby="" placeholder />
              {errors?.hometown?.type === "required" && <p className="text-small text-red">This field is required</p>}

            </div>
          </div>
        </div>

        <div className="row mb-20">
          <div className="col">
            <div className="form-group">
              <label htmlFor="first_last_name" className="text-bold text-small mb-2">Website</label>

              <input {...register("website", { required: false })} defaultValue={website} className="form-control"  aria-describedby="" placeholder />

            </div>
            <div className="form-group mt-20">

              <input type="checkbox" {...register("public_profile", { required: false })} defaultChecked={public_profile} className="form-check-input me-10 checkbox-small"  aria-describedby="" placeholder />

              <label htmlFor="test-checkbox" className="text-bold text-small mb-2">Check here if you want fans to see your info</label>
            </div>
            <div className="form-group mt-20">
              <p className="text-small">
                This site protected by reCAPCHA. The Google <a href="#" className="text-green-dark">Privacy Policy</a> and <a href="#" className="text-green-dark">Terms of Serivce</a> apply.
              </p>
            </div>
          </div>
        </div>

          {/* {team_id} */}

        <input {...register("team_id", { required: false })} defaultValue={team_id} type="hidden" className="form-control" aria-describedby="" />
        <input {...register("fk_captain_id", { required: false })} defaultValue={member_id} type="hidden" className="form-control" aria-describedby="" />
        <input {...register("shit", { required: false })} defaultValue={team_id} type="hidden" className="form-control" aria-describedby="" />

        <div className="mt-40">
          {/* <a href className="btn btn-outline-dark btn-ghost" onClick={onClick}>Save</a> */}
          {/* <input type="submit" value="Save" className="btn btn-outline-dark btn-ghost" /> */}

          <button className="btn btn-outline-dark btn-ghost text-black" type="button" disabled>
            <span className="spinner-border text-success me-10" role="status" aria-hidden="true" style={{display: 'inline-block'}}></span>
              <span className="" style={{color: 'black'}}>Saving...</span>
          </button>

          {/* Loading Spinner */}
          {teamMutation.isLoading ? (
            <button className="btn btn-outline-dark btn-ghost text-black" type="button" disabled>
              <span className="spinner-border text-success me-10" role="status" aria-hidden="true" style={{display: 'inline-block'}}></span>
                <span className="" style={{color: 'black'}}>Saving...</span>
            </button>
          ) : null}

          {/* Saved! */}
          {teamMutation.isSuccess ? (
            <input type="submit" value="Saved!" className="btn btn-outline-dark btn-ghost" />
          ) : null}

          {/* Save */}
          {teamMutation.isIdle ? (
            <input type="submit" value="Save" className="btn btn-outline-dark btn-ghost" />
          ) : null}

        </div>

        </form>

      {/* /Right Column */}

    </>
  );
}
