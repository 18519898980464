import React from 'react';

export default function StaticHome() {

    return (
    <>

      {/* This is important, make the main container always be px-0 */}
      {/* Main Container */}
      <div className="container px-0">
        {/* Black Header */}
        {/* Hero Text */}
        {/* Hero Image */}
        <section className="d-none d-md-flex mb-20 ">
          <div className="container  bg-black py-90">
            <div className="row">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                <h1 className="text-center text-yellow">Nick Pon</h1>
              </div>
            </div>
            <div className="row text-center">
              <div className="col mt-20">
                <a href="/" className="btn btn-outline-yellow btn-ghost">Registration Home</a>
                <a href="/" className="btn btn-outline-yellow btn-ghost">My Profile</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Hero Image */}
        {/* Hero Image > Mobile Version */}
        {/*
<section class="d-md-none">
<div class="container">
  <div class="row">
    <div class="col-sm p-0" id="banner-image"
    style='background: url("/wp-content/themes/lemons/assets/images/") no-repeat center center; width: 100%; min-height: 248px; height: 100%; background-size: cover;'>
    </div>
  </div>
</div>
</section>
*/}
        {/* /Hero Image > Mobile Version */}
        {/* /Hero Text */}
        {/* Other Events You Might Want to Enter */}
        <section className="mb-20">
          <div className="container px-30 py-10 px-lg-70 py-lg-20 bg-yellow">
            <div className="row">
              <div className="col text-uppercase text-bold text-center">
                Events You've Entered
              </div>
            </div>
          </div>
        </section>
        {/* Other Events You Might Want to Enter */}

        {/* Table Header Black*/}
        <section>
          <div className="container mb-20">
            {/* Desktop Version */}
            <div className="row bg-black px-70 py-20 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Location</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Date</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Team</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Car</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Status</h4>
              </div>
            </div>
            {/* /Desktop Version */}
            {/* TODO: */}
            {/* Mobile Version */}
            <div className="row bg-black px-30 py-20 d-md-none">
              <div className="col-6 my-auto">
                <h4 className="text-white text-uppercase font-weight-bold">Location</h4>
              </div>
              <div className="col-6 my-auto">
                <h4 className="text-white text-uppercase font-weight-bold">Event/Date</h4>
              </div>
            </div>
            {/* /Mobile Version */}
          </div>
        </section>
        {/* /Table Header Black*/}

        {/* Race Events Collection */}
        <section>
          {/* Single Race Event Beige */}
          {/* Desktop Version */}
          <div className="container mb-20 py-2 bg-beige">
            {/* Team i */}
            <div className="row bg-beige px-70 py-1 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="caps font-weight-bold">MSR Houston</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <p className="text-small">
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Dick Ass (1)</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-small">#1</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Accepted (OK)</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <div className="text-end">
                  <a href="#" className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>
              </div>
            </div>
            {/* /Team i */}
            {/* Team i */}
            <div className="row bg-beige px-70 py-1 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="caps font-weight-bold">MSR Houston</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <p className="text-small">
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Dick Ass (2)</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-small">#2</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Accepted (OK)</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <div className="text-end">
                  <a href="#" className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>
              </div>
            </div>
            {/* /Team i */}
            {/* Team i */}
            <div className="row bg-beige px-70 py-1 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="caps font-weight-bold">MSR Houston</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <p className="text-small">
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Dick Ass (3)</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-small">#3</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Accepted (OK)</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <div className="text-end">
                  <a href="#" className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>
              </div>
            </div>
            {/* /Team i */}
          </div>
          {/* /Desktop Version */}
          {/* TODO: */}
          {/* Mobile Version /*/}
          {/* /Single Race Event Beige */}
          {/* Single Race Event Beige */}
          {/* Desktop Version */}
          <div className="container mb-20 py-2 bg-beige">
            {/* Team i */}
            <div className="row bg-beige px-70 py-1 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="caps font-weight-bold">Sonoma Raceway</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <p className="text-small">
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Dick Ass (1)</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-small">#1</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Accepted (OK)</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <div className="text-end">
                  <a href="#" className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>
              </div>
            </div>
            {/* /Team i */}
          </div>
          {/* /Desktop Version */}
          {/* TODO: */}
          {/* Mobile Version /*/}
          {/* /Single Race Event Beige */}
          {/* Single Race Event Beige */}
          {/* Desktop Version */}
          <div className="container mb-20 py-2 bg-beige">
            {/* Team i */}
            <div className="row bg-beige px-70 py-1 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="caps font-weight-bold">Road Atlanta</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <p className="text-small">
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Dick Ass (1)</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-small">#1</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Accepted <span className="text-red">(NOT OK)</span></h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <div className="text-end">
                  <a href="#" className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>
              </div>
            </div>
            {/* /Team i */}
            {/* Team i */}
            <div className="row bg-beige px-70 py-1 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="caps font-weight-bold">Road Atlanta</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <p className="text-small">
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Dick Ass (2)</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-small">#2</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-small">Accepted <span className="text-red">(NOT OK)</span></h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <div className="text-end">
                  <a href="#" className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>
              </div>
            </div>
            {/* /Team i */}
          </div>
          {/* /Desktop Version */}
          {/* TODO: */}
          {/* Mobile Version /*/}
          {/* /Single Race Event Beige */}
        </section>

        {/* Race Events Collection */}

        {/* Other Events You Might Want to Enter */}
        <section className="mb-20">
          <div className="container px-30 py-10 px-lg-70 py-lg-20 bg-black">
            <div className="row">
              <div className="col text-uppercase text-bold text-center text-white">
                Other Events You Might Want to Enter
              </div>
            </div>
          </div>
        </section>
        {/* Other Events You Might Want to Enter */}

        {/* White Boxes Collection */}
        <section>
          {/* Desktop Version */}
          <div className="container mb-20 d-none d-md-flex">
            <div className="row px-70 py-30 border border-dark border-4">
              <div className="col-sm my-auto">
                <h4 className="caps font-weight-bold">Barber Motorsports, Leeds AL</h4>
              </div>
              <div className="col-sm my-auto">
                <p>
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm my-auto">
                <h4 className="font-weight-bold">Shine County Classic</h4>
              </div>
              <div className="col-sm my-auto">
                <div className="text-end">
                  <a href="/race/?id=" className="btn btn-outline-dark btn-ghost">Race Info</a>
                  <a href="https://registration.24hoursoflemons.com/login" className="btn btn-outline-dark btn-ghost">Register</a>
                  <a href="/spectator" className="btn btn-outline-dark btn-ghost">Spectator Tickets</a>
                </div>
              </div>
            </div>
          </div>
          {/* /Desktop Version */}
          {/* TODO: */}
          {/* Mobile Version /*/}
          {/* Desktop Version */}
          <div className="container mb-20 d-none d-md-flex">
            <div className="row px-70 py-30 border border-dark border-4">
              <div className="col-sm my-auto">
                <h4 className="caps font-weight-bold">Barber Motorsports, Leeds AL</h4>
              </div>
              <div className="col-sm my-auto">
                <p>
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm my-auto">
                <h4 className="font-weight-bold">Shine County Classic</h4>
              </div>
              <div className="col-sm my-auto">
                <div className="text-end">
                  <a href="/race/?id=" className="btn btn-outline-dark btn-ghost">Race Info</a>
                  <a href="https://registration.24hoursoflemons.com/login" className="btn btn-outline-dark btn-ghost">Register</a>
                  <a href="/spectator" className="btn btn-outline-dark btn-ghost">Spectator Tickets</a>
                </div>
              </div>
            </div>
          </div>
          {/* /Desktop Version */}
          {/* TODO: */}
          {/* Mobile Version /*/}
          {/* Desktop Version */}
          <div className="container mb-20 d-none d-md-flex">
            <div className="row px-70 py-30 border border-dark border-4">
              <div className="col-sm my-auto">
                <h4 className="caps font-weight-bold">Barber Motorsports, Leeds AL</h4>
              </div>
              <div className="col-sm my-auto">
                <p>
                  Nov 13 - Nov 14, 2021
                </p>
              </div>
              <div className="col-sm my-auto">
                <h4 className="font-weight-bold">Shine County Classic</h4>
              </div>
              <div className="col-sm my-auto">
                <div className="text-end">
                  <a href="/race/?id=" className="btn btn-outline-dark btn-ghost">Race Info</a>
                  <a href="https://registration.24hoursoflemons.com/login" className="btn btn-outline-dark btn-ghost">Register</a>
                  <a href="/spectator" className="btn btn-outline-dark btn-ghost">Spectator Tickets</a>
                </div>
              </div>
            </div>
          </div>
          {/* /Desktop Version */}
          {/* TODO: */}
          {/* Mobile Version /*/}
        </section>
        {/* White Boxes Collection */}
      </div>
      {/* /Main Container */}

      </>
    );
  }
