// src/components/Member.js
import React from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES, URL_API_ROOT, URL_API_V2} from '../constants'
import HeroText from '../components/HeroText';
import { useForm } from "react-hook-form";

// Store
import useStore from '../store/Store';
import useMemberStore from '../store/MemberStore';

// Fetch Queries
async function fetchMember(member_id){
  const {data} = await axios.get(`${URL_API_V2}/members/${member_id}`);
  return data;
}

function Login() {

  // React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

const firstName = useMemberStore((state) => state.firstName);
const memberId = useMemberStore((state) => state.memberId);
const setFirstName = useMemberStore((state) => state.setFirstName);
const setMemberId = useMemberStore((state) => state.setMemberId);

  // const memberId = '';
  // const getMemberId = useMemberStore(state => state.memberId);
  // const setMemberId = useMemberStore(state => state.setMemberId(memberId));

  // const {data, error, isError, isLoading, isFetching } = useQuery('member', fetchMember);
/*
const {data, error, isError, isLoading, isFetching } = useQuery(['member', getMemberId], () => fetchMember(getMemberId), {
  enabled: false,

});
*/

  const onSubmit = data => console.log(data);

  const handleSubmitData = data => {
    console.log("-------------------------------");
    console.log(data);
    console.log(data.member_id);
    console.log("-------------------------------");

  };

  // const fart = e => alert("Fart");
  // const fart = (x) => alert("Fart: ". x);
  // const fart = (e) => alert("Fart: ");
  // const fart = e => console.log(e);
  // const fart = e => console.log(firstName);
  // const fart = e => alert(firstName);
  // const fart = () => alert(firstName);

  const fart = () => {
    console.log("-------------------------------");
    console.log(firstName);
    console.log("-------------------------------");
  };

    return (
    <>

      {/* This is important, make the main container always be px-0 */}

      {/* Main Container */}
      <div className="container px-0">
        {/* Black Header */}
        <section className="d-none d-md-flex mb-20 ">
          <div className="container  bg-black py-90">
            <div className="row">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                <h1 className="text-center text-yellow">Log In</h1>
              </div>
            </div>
            <div className="row text-center">
              <div className="col mt-20">
              </div>
            </div>
          </div>
        </section>
        {/* /Black Header */}

        {/* Yellow Box */}
        <section>

          <form onSubmit={handleSubmit(handleSubmitData)}>

            <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-yellow mb-20">
              <div className="row">
                <div className="col col-lg-6 offset-lg-3">

                  <h2>Log In</h2>

                  <div>
                    First Name:
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-20">
                    <label htmlFor="first_last_name" className="text-bold text-small mb-2">Member ID*</label>
                    <input
                      {...register("member_id", { required: true, minLength: 2 })}
                      className="form-control"
                      id="member_id"
                      aria-describedby="emailHelp"
                      onChange={(e) => setMemberId(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-20">
                    <label htmlFor="first_last_name" className="text-bold text-small mb-2">Email*</label>
                    <input {...register("email", { required: false, minLength: 2 })}  className="form-control" id="email" aria-describedby="emailHelp" />
                  </div>

                  <div className="form-group mb-20">
                    <label htmlFor="first_last_name" className="text-bold text-small mb-2">Password*</label>
                    <input {...register("password", { required: false, minLength: 2 })}  className="form-control" id="password" aria-describedby="emailHelp" />
                  </div>

                </div>
              </div>

              <div className="row mt-20">
                <div className="col col-lg-6 offset-lg-3">
                  <input type="submit" value="Log In" className="btn btn-outline-dark btn-ghost" />
                  <input
                    type="button"
                    value="Log Out"
                    className="btn btn-outline-dark btn-ghost"
                    onClick={fart}
                  />
                </div>
              </div>

            </div>

          </form>

        </section>
        {/* /Yellow Box */}

      </div>
      {/* /Main Container */}
      </>
    );
  }

export default Login;
