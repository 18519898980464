import React from 'react';

export default function HeroText({data, activeButton}) {

  return (
    <>
      {/* Hero Image */}
      <section className="d-none d-md-flex mb-20 ">
        <div className="container  bg-black py-90">
          <div className="row">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
              <h1 className="text-center text-yellow">{data.first_name} {data.last_name}</h1>
            </div>
          </div>
          <div className="row text-center">
            <div className="col mt-20">

              <a href="/home" className={activeButton == 'home' ? 'btn btn-outline-yellow btn-ghost active' : 'btn btn-outline-yellow btn-ghost'}>Registration Home</a>

              <a href="/member" className={activeButton == 'member' ? 'btn btn-outline-yellow btn-ghost active' : 'btn btn-outline-yellow btn-ghost'}>My Profile</a>

            </div>
          </div>
        </div>
      </section>
      {/* /Hero Image */}
    </>
  );
}
