import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import pipe from 'ramda.pipe'

// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.log('  applying', args)
      set(...args)
      console.log('  new state', get())
    },
    get,
    api
  )

const createStore = pipe(log, devtools, persist, create)

const useMemberStore = createStore(
  (set, get) => ({
    member: '',
    firstName: '',
    memberId: '',
    // setMemberId: (some_id) => set(state => ({ memberId: some_id })),
     setFirstName: (firstName) => set({ firstName }),
     setMemberId: (memberId) => set({ memberId }),
  }),
  {
    name: 'member', // unique name
    getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
  }

)

export default useMemberStore;
