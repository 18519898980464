import React from 'react';
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES, URL_SIGNUPS, URL_API_ROOT} from '../constants'
import HeroText from '../components/HeroText';
import { useForm } from "react-hook-form";

// URLs
// const url = `http://localhost:8080/members/${MEMBER_ID}`;
// const url_member = `http://localhost:8080/members`;
// const url_signup = `http://localhost:8080/signups`;
// const url_race = `http://localhost:8080/races`;
// const url_team = `http://localhost:8080/teams`;

const url = `${URL_MEMBERS}/${MEMBER_ID}`;
const url_member = `${URL_MEMBERS}`;
const url_signup = `${URL_SIGNUPS}`;
const url_race = `${URL_RACES}`;
const url_team = `${URL_TEAMS}`;

// Fetch Queries
async function fetchMember(){
	const {data} = await axios.get(url);
  return data;
}

// TODO: Remove this, this was just a test.
function RenderName({data}) {
  return (
    <>
      <div>
        <h1>{data.first_name} {data.last_name}</h1>
      </div>
    </>
  );
}

async function updateMemberInfo(thePostData) {

  console.log("Hello 22");
  console.log("thePostData", thePostData);
  console.log("thePostData ID", thePostData.member_id);
  // const response = await axios.post(url_member + '/' + thePostData.member_id, thePostData)
  const response = await axios.put(url_member + '/' + thePostData.member_id, thePostData)
  // const response = await axios.post(url_member + '/' + thePostData.memberId, thePostData)

}

function MemberEdit() {

  const {data, error, isError, isLoading, isFetching } = useQuery('member', fetchMember);

  // React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
      } = useForm();

  // Save the member info
  const memberMutation = useMutation((thePostData) => updateMemberInfo(thePostData));

  // const onSubmit = data => console.log(data);
  const onSubmit = data => console.log(data);

  const handleSubmitData = data => {
    console.log("-------------------------------");
    console.log(data);
    console.log("-------------------------------");

    memberMutation.mutate({
      member_id: data.memberId,
      first_name: data.firstName,
      last_name: data.lastName,
      phone1: data.phone1,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country: data.country,
      emergency_first_name: data.emergencyFirstName,
      emergency_last_name: data.emergencyLastName,
      emergency_phone1: data.emergencyPhone1,
    })
  };

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  // if (!data) return "Loading data...";

  if (data) {
  return (
    <>

      {/* This is important, make the main container always be px-0 */}
      {/* Main Container */}
      <div className="container px-0">

        <HeroText data={data}  />

        {/* Beige Box */}
        <section>

          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <form onSubmit={handleSubmit(handleSubmitData)}>
          {/* <form onSubmit={handleSubmit(memberMutation)}> */}

          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h2>Edit Profile</h2>
                <div className="form-group mb-20">
                  <label htmlFor="first_name" className="text-bold text-small mb-2">First Name*</label>

                  <input {...register("firstName", { required: true, minLength: 2 })} defaultValue={data.first_name} className="form-control" id="first_name" aria-describedby="emailHelp" placeholder />

                  {errors?.firstName?.type === "required" && <p className="text-small text-red">This field is required</p>}
                  {errors?.firstName?.type === "minLength" && (
                    <p className="text-small text-red">First name must be at least two characters</p>
                  )}

                </div>
                <div className="form-group mb-20">
                  <label htmlFor="last_name" className="text-bold text-small mb-2">Last Name*</label>

                  <input {...register("lastName", { required: true, minLength: 2 })} defaultValue={data.last_name} type="text" className="form-control" id="last_name" aria-describedby="emailHelp" placeholder />

                  {errors?.lastName?.type === "required" && <p className="text-small text-red">This field is required</p>}
                  {errors?.lastName?.type === "minLength" && (
                    <p className="text-small text-red">Last name must be at least two characters</p>
                  )}

                </div>
                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Cell Phone*</label>

                  <input {...register("phone1", { required: true })} defaultValue={data.phone1} type="text" className="form-control" id="phone1" aria-describedby="emailHelp" placeholder />

                  {errors?.phone1?.type === "required" && <p className="text-small text-red">This field is required</p>}

                </div>
                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Street Address 1*</label>

                  {/* <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder /> */}
                  <input {...register("address1", { required: true })} defaultValue={data.address1} type="text" className="form-control" id="address1" aria-describedby="emailHelp" placeholder />
                  {errors?.address1?.type === "required" && <p className="text-small text-red">This field is required</p>}

                </div>

                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Street Address 2</label>

                  <input {...register("address2", { required: false })} defaultValue={data.address2} type="text" className="form-control" id="address2" aria-describedby="emailHelp" placeholder />
                  {errors?.address2?.type === "required" && <p className="text-small text-red">This field is required</p>}

                </div>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group mb-20">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">City*</label>

                      <input {...register("city", { required: true })} defaultValue={data.city} type="text" className="form-control" id="city" aria-describedby="emailHelp" placeholder />
                      {errors?.city?.type === "required" && <p className="text-small text-red">This field is required</p>}

                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="form-group mb-20">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">State*</label>

                      {/* <select className="form-select" aria-label="Default select example"> */}
                      {/*   <option selected /> */}
                      {/*   <option value={1}>One</option> */}
                      {/*   <option value={2}>Two</option> */}
                      {/*   <option value={3}>Three</option> */}
                      {/* </select> */}

											<select {...register("state", { required: true })} className="form-select" aria-label="Default select example"  id="state-id" defaultValue={data.state}>
												<option value="">-- Select State --</option>
												<option value="--">(Non-US)</option>
												<option value="AL">Alabama</option>
												<option value="AK">Alaska</option>
												<option value="AZ">Arizona</option>
												<option value="AR">Arkansas</option>
												<option value="CA" selected="">California</option>
												<option value="CO">Colorado</option>
												<option value="CT">Connecticut</option>
												<option value="DE">Delaware</option>
												<option value="DC">Dist of Columbia</option>
												<option value="FL">Florida</option>
												<option value="GA">Georgia</option>
												<option value="HI">Hawaii</option>
												<option value="ID">Idaho</option>
												<option value="IL">Illinois</option>
												<option value="IN">Indiana</option>
												<option value="IA">Iowa</option>
												<option value="KS">Kansas</option>
												<option value="KY">Kentucky</option>
												<option value="LA">Louisiana</option>
												<option value="ME">Maine</option>
												<option value="MD">Maryland</option>
												<option value="MA">Massachusetts</option>
												<option value="MI">Michigan</option>
												<option value="MN">Minnesota</option>
												<option value="MS">Mississippi</option>
												<option value="MO">Missouri</option>
												<option value="MT">Montana</option>
												<option value="NE">Nebraska</option>
												<option value="NV">Nevada</option>
												<option value="NH">New Hampshire</option>
												<option value="NJ">New Jersey</option>
												<option value="NM">New Mexico</option>
												<option value="NY">New York</option>
												<option value="NC">North Carolina</option>
												<option value="ND">North Dakota</option>
												<option value="OH">Ohio</option>
												<option value="OK">Oklahoma</option>
												<option value="OR">Oregon</option>
												<option value="PA">Pennsylvania</option>
												<option value="RI">Rhode Island</option>
												<option value="SC">South Carolina</option>
												<option value="SD">South Dakota</option>
												<option value="TN">Tennessee</option>
												<option value="TX">Texas</option>
												<option value="UT">Utah</option>
												<option value="VT">Vermont</option>
												<option value="VA">Virginia</option>
												<option value="WA">Washington</option>
												<option value="WV">West Virginia</option>
												<option value="WI">Wisconsin</option>
												<option value="WY">Wyoming</option>
											</select>
                      {errors?.state?.type === "required" && <p className="text-small text-red">This field is required</p>}

                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group mb-20">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">Zip*</label>

                      <input {...register("zip", { required: true })} defaultValue={data.zip} type="text" className="form-control" id="zip" aria-describedby="emailHelp" placeholder />
                      {errors?.zip?.type === "required" && <p className="text-small text-red">This field is required</p>}

                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="form-group mb-20">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">Country*</label>

                      {/* <select className="form-select" aria-label="Default select example"> */}
                      {/*   <option selected /> */}
                      {/*   <option value={1}>One</option> */}
                      {/*   <option value={2}>Two</option> */}
                      {/*   <option value={3}>Three</option> */}
                      {/* </select> */}

											<select {...register("country", { required: true })} className="form-select" aria-label="Default select example"  id="country-id" defaultValue={data.country}>
												<option value="">-- Select Country --</option>
												<option value="US" selected="">United States</option>
												<option value="AF">Afghanistan</option>
												<option value="AL">Albania</option>
												<option value="DZ">Algeria</option>
												<option value="AS">American Samoa</option>
												<option value="AD">Andorra</option>
												<option value="AO">Angola</option>
												<option value="AI">Anguilla</option>
												<option value="AQ">Antarctica</option>
												<option value="AG">Antigua And Barbuda</option>
												<option value="AR">Argentina</option>
												<option value="AM">Armenia</option>
												<option value="AW">Aruba</option>
												<option value="AU">Australia</option>
												<option value="AT">Austria</option>
												<option value="AZ">Azerbaijan</option>
												<option value="BS">Bahamas</option>
												<option value="BH">Bahrain</option>
												<option value="BD">Bangladesh</option>
												<option value="BB">Barbados</option>
												<option value="BY">Belarus</option>
												<option value="BE">Belgium</option>
												<option value="BZ">Belize</option>
												<option value="BJ">Benin</option>
												<option value="BM">Bermuda</option>
												<option value="BT">Bhutan</option>
												<option value="BO">Bolivia</option>
												<option value="BA">Bosnia And Herzegowina</option>
												<option value="BW">Botswana</option>
												<option value="BV">Bouvet Island</option>
												<option value="BR">Brazil</option>
												<option value="BN">Brunei Darussalam</option>
												<option value="BG">Bulgaria</option>
												<option value="BF">Burkina Faso</option>
												<option value="BI">Burundi</option>
												<option value="KH">Cambodia</option>
												<option value="CM">Cameroon</option>
												<option value="CA">Canada</option>
												<option value="CV">Cape Verde</option>
												<option value="KY">Cayman Islands</option>
												<option value="CF">Central African Rep</option>
												<option value="TD">Chad</option>
												<option value="CL">Chile</option>
												<option value="CN">China</option>
												<option value="CX">Christmas Island</option>
												<option value="CC">Cocos Islands</option>
												<option value="CO">Colombia</option>
												<option value="KM">Comoros</option>
												<option value="CG">Congo</option>
												<option value="CK">Cook Islands</option>
												<option value="CR">Costa Rica</option>
												<option value="CI">Cote D'ivoire</option>
												<option value="HR">Croatia</option>
												<option value="CU">Cuba</option>
												<option value="CY">Cyprus</option>
												<option value="CZ">Czech Republic</option>
												<option value="DK">Denmark</option>
												<option value="DJ">Djibouti</option>
												<option value="DM">Dominica</option>
												<option value="DO">Dominican Republic</option>
												<option value="TP">East Timor</option>
												<option value="EC">Ecuador</option>
												<option value="EG">Egypt</option>
												<option value="SV">El Salvador</option>
												<option value="GQ">Equatorial Guinea</option>
												<option value="ER">Eritrea</option>
												<option value="EE">Estonia</option>
												<option value="ET">Ethiopia</option>
												<option value="FK">Falkland Islands (Malvinas)</option>
												<option value="FO">Faroe Islands</option>
												<option value="FJ">Fiji</option>
												<option value="FI">Finland</option>
												<option value="FR">France</option>
												<option value="GF">French Guiana</option>
												<option value="PF">French Polynesia</option>
												<option value="TF">French S. Territories</option>
												<option value="GA">Gabon</option>
												<option value="GM">Gambia</option>
												<option value="GE">Georgia</option>
												<option value="DE">Germany</option>
												<option value="GH">Ghana</option>
												<option value="GI">Gibraltar</option>
												<option value="GR">Greece</option>
												<option value="GL">Greenland</option>
												<option value="GD">Grenada</option>
												<option value="GP">Guadeloupe</option>
												<option value="GU">Guam</option>
												<option value="GT">Guatemala</option>
												<option value="GN">Guinea</option>
												<option value="GW">Guinea-bissau</option>
												<option value="GY">Guyana</option>
												<option value="HT">Haiti</option>
												<option value="HN">Honduras</option>
												<option value="HK">Hong Kong</option>
												<option value="HU">Hungary</option>
												<option value="IS">Iceland</option>
												<option value="IN">India</option>
												<option value="ID">Indonesia</option>
												<option value="IR">Iran</option>
												<option value="IQ">Iraq</option>
												<option value="IE">Ireland</option>
												<option value="IL">Israel</option>
												<option value="IT">Italy</option>
												<option value="JM">Jamaica</option>
												<option value="JP">Japan</option>
												<option value="JO">Jordan</option>
												<option value="KZ">Kazakhstan</option>
												<option value="KE">Kenya</option>
												<option value="KI">Kiribati</option>
												<option value="KP">Korea (North)</option>
												<option value="KR">Korea (South)</option>
												<option value="KW">Kuwait</option>
												<option value="KG">Kyrgyzstan</option>
												<option value="LA">Laos</option>
												<option value="LV">Latvia</option>
												<option value="LB">Lebanon</option>
												<option value="LS">Lesotho</option>
												<option value="LR">Liberia</option>
												<option value="LY">Libya</option>
												<option value="LI">Liechtenstein</option>
												<option value="LT">Lithuania</option>
												<option value="LU">Luxembourg</option>
												<option value="MO">Macau</option>
												<option value="MK">Macedonia</option>
												<option value="MG">Madagascar</option>
												<option value="MW">Malawi</option>
												<option value="MY">Malaysia</option>
												<option value="MV">Maldives</option>
												<option value="ML">Mali</option>
												<option value="MT">Malta</option>
												<option value="MH">Marshall Islands</option>
												<option value="MQ">Martinique</option>
												<option value="MR">Mauritania</option>
												<option value="MU">Mauritius</option>
												<option value="YT">Mayotte</option>
												<option value="MX">Mexico</option>
												<option value="FM">Micronesia</option>
												<option value="MD">Moldova</option>
												<option value="MC">Monaco</option>
												<option value="MN">Mongolia</option>
												<option value="MS">Montserrat</option>
												<option value="MA">Morocco</option>
												<option value="MZ">Mozambique</option>
												<option value="MM">Myanmar</option>
												<option value="NA">Namibia</option>
												<option value="NR">Nauru</option>
												<option value="NP">Nepal</option>
												<option value="NL">Netherlands</option>
												<option value="AN">Netherlands Antilles</option>
												<option value="NC">New Caledonia</option>
												<option value="NZ">New Zealand</option>
												<option value="NI">Nicaragua</option>
												<option value="NE">Niger</option>
												<option value="NG">Nigeria</option>
												<option value="NU">Niue</option>
												<option value="NF">Norfolk Island</option>
												<option value="MP">Northern Mariana Islands</option>
												<option value="NO">Norway</option>
												<option value="OM">Oman</option>
												<option value="PK">Pakistan</option>
												<option value="PW">Palau</option>
												<option value="PA">Panama</option>
												<option value="PG">Papua New Guinea</option>
												<option value="PY">Paraguay</option>
												<option value="PE">Peru</option>
												<option value="PH">Philippines</option>
												<option value="PN">Pitcairn</option>
												<option value="PL">Poland</option>
												<option value="PT">Portugal</option>
												<option value="PR">Puerto Rico</option>
												<option value="QA">Qatar</option>
												<option value="RE">Reunion</option>
												<option value="RO">Romania</option>
												<option value="RU">Russian Federation</option>
												<option value="RW">Rwanda</option>
												<option value="KN">Saint Kitts And Nevis</option>
												<option value="LC">Saint Lucia</option>
												<option value="VC">St Vincent/Grenadines</option>
												<option value="WS">Samoa</option>
												<option value="SM">San Marino</option>
												<option value="ST">Sao Tome</option>
												<option value="SA">Saudi Arabia</option>
												<option value="SN">Senegal</option>
												<option value="SC">Seychelles</option>
												<option value="SL">Sierra Leone</option>
												<option value="SG">Singapore</option>
												<option value="SK">Slovakia</option>
												<option value="SI">Slovenia</option>
												<option value="SB">Solomon Islands</option>
												<option value="SO">Somalia</option>
												<option value="ZA">South Africa</option>
												<option value="ES">Spain</option>
												<option value="LK">Sri Lanka</option>
												<option value="SH">St. Helena</option>
												<option value="PM">St.Pierre</option>
												<option value="SD">Sudan</option>
												<option value="SR">Suriname</option>
												<option value="SZ">Swaziland</option>
												<option value="SE">Sweden</option>
												<option value="CH">Switzerland</option>
												<option value="SY">Syrian Arab Republic</option>
												<option value="TW">Taiwan</option>
												<option value="TJ">Tajikistan</option>
												<option value="TZ">Tanzania</option>
												<option value="TH">Thailand</option>
												<option value="TG">Togo</option>
												<option value="TK">Tokelau</option>
												<option value="TO">Tonga</option>
												<option value="TT">Trinidad And Tobago</option>
												<option value="TN">Tunisia</option>
												<option value="TR">Turkey</option>
												<option value="TM">Turkmenistan</option>
												<option value="TV">Tuvalu</option>
												<option value="UG">Uganda</option>
												<option value="UA">Ukraine</option>
												<option value="AE">United Arab Emirates</option>
												<option value="UK">United Kingdom</option>
												<option value="UY">Uruguay</option>
												<option value="UZ">Uzbekistan</option>
												<option value="VU">Vanuatu</option>
												<option value="VA">Vatican City State</option>
												<option value="VE">Venezuela</option>
												<option value="VN">Viet Nam</option>
												<option value="VG">Virgin Islands (British)</option>
												<option value="VI">Virgin Islands (U.S.)</option>
												<option value="EH">Western Sahara</option>
												<option value="YE">Yemen</option>
												<option value="YU">Yugoslavia</option>
												<option value="ZR">Zaire</option>
												<option value="ZM">Zambia</option>
												<option value="ZW">Zimbabwe</option>
											</select>
                      {errors?.country?.type === "required" && <p className="text-small text-red">This field is required</p>}

                    </div>
                  </div>
                </div>

                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Emergency Contact First Name*</label>

                  <input {...register("emergencyFirstName", { required: true, minLength: 2 })} defaultValue={data.emergency_first_name} className="form-control" id="first_name" aria-describedby="emailHelp" placeholder />
                  {errors?.emergencyFirstName?.type === "required" && <p className="text-small text-red">This field is required</p>}
                  {errors?.emergencyFirstName?.type === "minLength" && (
                    <p className="text-small text-red">Emergency Contact first name must be at least two characters</p>
                  )}

                </div>

                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Emergency Contact Last Name*</label>
                  <input {...register("emergencyLastName", { required: true, minLength: 2 })} defaultValue={data.emergency_last_name} className="form-control" id="first_name" aria-describedby="emailHelp" placeholder />
                  {errors?.emergencyLastName?.type === "required" && <p className="text-small text-red">This field is required</p>}
                  {errors?.emergencyLastName?.type === "minLength" && (
                    <p className="text-small text-red">Emergency Contact last name must be at least two characters</p>
                  )}

                </div>

                <div className="form-group mb-20">
                  <label htmlFor="first_last_name" className="text-bold text-small mb-2">Emergency Contact Phone*</label>
                  <input {...register("emergencyPhone1", { required: true })} defaultValue={data.emergency_phone1} type="text" className="form-control" id="phone1" aria-describedby="emailHelp" placeholder />
                  {errors?.emergencyPhone1?.type === "required" && <p className="text-small text-red">This field is required</p>}
                </div>
              </div>

              <input {...register("memberId", { required: false })} defaultValue={data.member_id} type="hidden" className="form-control" id="memberId" aria-describedby="emailHelp" placeholder />

            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <input type="submit" value="Enter" className="btn btn-outline-dark btn-ghost" />
                <p className="text-small mt-20">
                  This site is protected by reCAPTCHA aand the Google Privacy Policy and Term and Service apply.
                </p>
              </div>
            </div>
          </div>

          </form>

        </section>
        {/* /Beige Box */}
      </div>
      {/* /Main Container */}
      </>
    );

    }

  }

export default MemberEdit;
