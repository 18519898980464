//-------------------------------
// Users

// Greg Lang
// export const MEMBER_ID = '18227';

// Joseph Barton
// export const MEMBER_ID = '51418';

// Ahmad Varoqua
// export const MEMBER_ID = '63188';

// Brian Pollock
export const MEMBER_ID = '17963';

// Antonio Unanue
// export const MEMBER_ID = '61256';

//-------------------------------
// URLs
// export const URL_MEMBERS = `http://localhost:8080/members`;
// export const URL_SIGNUPS = `http://localhost:8080/signups`;
// export const URL_RACES = `http://localhost:8080/races`;
// export const URL_TEAMS = `http://localhost:8080/teams`;

// export const URL_API_ROOT = `http://54.219.178.152`;

// THIS WORKS BUT USES THE OLD HAND WRITTEN API:
// export const URL_API_ROOT = `https://api.24hoursoflemons.com`;
// export const URL_MEMBERS = `${URL_API_ROOT}/members`;
// export const URL_SIGNUPS = `${URL_API_ROOT}/signups`;
// export const URL_RACES = `${URL_API_ROOT}/races`;
// export const URL_TEAMS = `${URL_API_ROOT}/teams`;

// NEW v2 API (Auto API generated by php-crud-api)
export const URL_API_ROOT = `http://localhost:8080`;
export const URL_API_V2 = `http://localhost:8080/api/records`;

export const URL_MEMBERS = `${URL_API_ROOT}/api/records/members`;
export const URL_SIGNUPS = `${URL_API_ROOT}/api/records/signups`;
export const URL_RACES = `${URL_API_ROOT}/api/records/races`;
export const URL_TEAMS = `${URL_API_ROOT}/api/records/teams`;
