import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_API_ROOT} from '../constants'

function UserAdd(){

  // Need these to store the local forms state before sending it out to the server
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

	// const url = 'https://eof4ms7376e7iiw.m.pipedream.net';
	const url = `${URL_API_ROOT}/test-users/add`;
{/*
	const addMutation = useMutation(userAdd => {
		return axios.post(url, userAdd)
	})
*/}

  async function userAdd(thePostData) {
  	const response = await axios.post(url, thePostData)
  }

  const addMutation = useMutation((thePostData) => userAdd(thePostData));

	return (
	 <div>
	   {addMutation.isLoading ? (
	     'Adding todo...'
	    ) : (
	      <>
	        {addMutation.isError ? (
	          <div>An error occurred: {addMutation.error.message}</div>
	        ) : null}

	        {addMutation.isSuccess ? <div>User added!</div> : null}

           <input type="text" value={name} onChange={e => setName(e.target.value)} placeHolder="name" />
           <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeHolder="email" />

	        <button
	          onClick={() => {
	            // mutation.mutate({ name: 'James Hong', email: 'james@example.com', phone: '555-555-5555' })
	            addMutation.mutate({ name: name, email: email, phone: '555-555-5555' })
	          }}
	        >
						Add User
	        </button>
	      </>
	    )}
	  </div>
	)

}

export default UserAdd;
