import { useForm } from "react-hook-form";

export const TeamForm2 = ({ defaultValues, onFormSubmit, isLoading }) => {
  const { register, handleSubmit } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data)
  })

  return (
    <form onSubmit={onSubmit}>

      <input {...register("name", { required: true, minLength: 2 })}
        type="text"
        className="form-control"
        placeholder="name"
      />

      <input {...register("hometown", { required: true, minLength: 2 })}
        type="text"
        className="form-control"
        placeholder="hometown"
      />

      <input {...register("fk_captain_id", { required: true, minLength: 2 })}
        type="hidden"
        className="form-control"
        placeholder="fk_captain_id"
      />

      <input
        type="submit"
        value="Save"
        className="btn btn-outline-dark btn-ghost"
      />

    </form>
  );
};
