import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom';

import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants/Constants';
import HeroText from '../components/HeroText';
import ListRacesWhite from '../components/ListRacesWhite';
import ListRacesBeige from '../components/ListRacesBeige';
import TeamInfoForm from '../components/TeamInfoForm';
import MyTeamsRadioButtons from '../components/MyTeamsRadioButtons';

// Fetch Queries

// Other Functions
function spitOut(something, e) {
  console.log(something);
  console.log(e);
}

function bigAlert(something) {
  alert(something);
}

/*
function showTeamInfoEdit() {
  // alert("Now showing team info");
  setTeamInfoVisible(true);
}
*/

async function fetchTeams(someId){
  const {data} = await axios.get(URL_MEMBERS + '/' + someId + '/teams');
  return data;
}

async function fetchTeam(someId){
  // Don't run the query if not fed an integer ID, e.g. if "new" is sent over, do nothing
  if (Number.isInteger(someId)) {
    const {data} = await axios.get(URL_TEAMS + '/' + someId);
    return data;
  }
}

function TeamInfoEdit() {

  // const [teamId, setTeamId] = useState('');
  const [teamId, setTeamId] = useState();
  const [memberId, setMemberId] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamConcept, setTeamConcept] = useState('');
  const [teamHometown, setTeamHometown] = useState('');
  const [teamWebsite, setTeamWebsite] = useState('');
  const [teamPublicProfile, setTeamPublicProfile] = useState('');
  const [mode, setMode] = useState('');
  const [myTeams, setMyTeams] = useState();
  const [teamInfoVisible, setTeamInfoVisible] = useState(false);

  // Get the id from the URL
  // NOTE: Don't forget, you can't just use some random variable here, it has to match whatever is
  // defined in react router, e.g. :id, therefore, have to use id when destructuring from useParams.
  const { id } = useParams();

  /*
  const {isIdle: isIdleTeams, data: data_teams, isLoading: isLoadingTeams } = useQuery(['my_teams', MEMBER_ID], () => fetchTeams(MEMBER_ID), {
    onSuccess: (data_teams) => {
      setMyTeams(data_teams);
    }
  });
  */

  const {isIdle: isIdleTeam, data: data_team, isLoading: isLoadingTeam } = useQuery(['team', teamId], () => fetchTeam(teamId), {
    enabled: !!teamId,
    onSuccess: (data_team) => {
      setTeamId(data_team[0].team_id);
      setTeamName(data_team[0].name);
      setTeamConcept(data_team[0].concept);
      setTeamHometown(data_team[0].hometown);
      setTeamWebsite(data_team[0].website);
      setTeamPublicProfile(data_team[0].public_profile);
    }
  });

  // Handle Radio Button changes
  const handleChange = (event) => {
    // setTeamId(event.target.value);
    console.log('Radio button clicked: ', event.target.value);
    console.log('Radio button clicked: ', parseInt(event.target.value));
    console.log('Radio button clicked: ', +event.target.value);

    if (event.target.value == "new") {
      console.log("This is a new entry.");
      setTeamId(event.target.value);
      setTeamName('');
      setTeamConcept('');
      setTeamHometown('');
      setTeamWebsite('');
      setTeamPublicProfile(0);
      setMode("new");
    }
    else {
      console.log("We are editing an existing team.");
      // Set the teamId to an integer (hence the + in front of event, similar to parseInt())
      setTeamId(+event.target.value);
      setMode("edit");
    }
  }

  const handleChange2 = (newValue) => {
    setTeamId(newValue);
  }

  console.log("Team Info Visible: ", teamInfoVisible);

  const showTeamInfoEdit = () => {
    // alert("Now showing team info");
    setTeamInfoVisible(true);
    console.log("Team Info Visible: ", teamInfoVisible);
  }

  return (
    <>
      {/* Main Container */}
      <div className="container px-0">

        {/* <HeroText data={data_member[0]} /> */}

        {/* Beige Box */}
        {/* <section> */}
        <section style={{display: teamInfoVisible == false ? 'none' : 'block' }}>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">

            <h1 className="text-center mb-50">Team Info</h1>

            <div className="row">

              {/* Left Column */}
              <div className="col bor0">
                <h2 className="mb-30">Add Your Team</h2>

                <div className="form-group mt-20 mb-20">
                  {/* <input className="form-check-input me-20" type="radio" name="team_id" value="123" /> */}

                    <input
                      className="form-check-input me-20"
                      type="radio"
                      name="team_id"
                      value="13060"
                      checked={teamId == "13060"}
                      onChange={handleChange}
                    />

                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Team 1</label>
                </div>

                <div className="form-group mt-20 mb-20">
                  {/* <input className="form-check-input me-20" type="radio" name="team_id" value="456" /> */}

                    <input
                      className="form-check-input me-20"
                      type="radio"
                      name="team_id"
                      value="13275"
                      checked={teamId == "13275"}
                      onChange={handleChange}
                    />

                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Team 2</label>
                </div>

                <div className="form-group mt-20 mb-20">
                  {/* <input className="form-check-input me-20" type="radio" name="team" value="new" /> */}

                    <input
                      className="form-check-input me-20"
                      type="radio"
                      name="team_id"
                      value="new"
                      checked={teamId == "new"}
                      onChange={handleChange}
                    />

                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Add New Team</label>
                </div>

                {/* <MyTeamsRadioButtons */}
                {/*   member_id={MEMBER_ID} */}
                {/*   onChange={handleChange2} */}
                {/* /> */}

                <div className="mt-40">
                  {/* <a href className="btn btn-outline-dark btn-ghost">Save</a> */}
                    <a href className="btn btn-outline-dark btn-ghost" onClick={() => bigAlert(teamId)}>Save</a>
                  <a href className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>

                <div className="">
                  <a href className="btn btn-outline-dark btn-ghost px-75" onClick={() => { if (window.confirm('Are you sure you wish to delete this team?')) this.onCancel() } }>Kill This Team For Eternity*</a>
                </div>

                <div>
                  <p className="text-small py-20 pe-120">
                    *This team entry will be gone forever and you will never, ever, ever be able to get it back!
                  </p>
                </div>

              </div>
              {/* /Left Column */}

              {/* Right Column */}
                <div className="col ps-60 bor0" style={{borderLeft: '1px solid black', display: 'block'}}>
                <h2 className="mb-30">Team Details</h2>

                  {/* {teamId} */}
                  {/* <br /> */}
                  {/* {mode} */}
                  {/* <br /> */}
                  {/* {MEMBER_ID} */}
                  {/* <hr /> */}

                  <TeamInfoForm
                    name={teamName}
                    concept={teamConcept}
                    hometown={teamHometown}
                    website={teamWebsite}
                    public_profile={teamPublicProfile}
                    team_id={teamId}
                    member_id={MEMBER_ID}
                    mode={mode}
                  />

                  {/*
<button className="btn btn-outline-dark btn-ghost text-black" type="button" disabled>
  <span className="spinner-border text-success me-10" role="status" aria-hidden="true" style={{display: 'inline-block'}}></span>
    <span className="" style={{color: 'black'}}>Saving...</span>
</button>
                  */}

                  {/*
                  <TeamInfoForm
                    name="Joker XL"
                    concept="This my concept."
                    hometown="Paterson"
                    website="www.myspace.com"
                    public_profile={1}
                    team_id="13060"
                    member_id="63188"
                    mode="edit"
                  />

                  <hr />

                  <TeamInfoForm
                    name=""
                    concept=""
                    hometown=""
                    website=""
                    public_profile={0}
                    team_id=""
                    member_id="63188"
                    mode="add"
                  />

                  */}

                </div>
              {/* /Right Column */}

            </div>
          </div>
        </section>
        {/* /Beige Box */}

        {/* Beige Box */}
        <section style={{display: teamInfoVisible == false ? 'block' : 'none' }}>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40">Team Info</h1>
                <h2 className="text-center mb-30" style={{}}>Add Your Team</h2>

                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Team 1</label>
                </div>

                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Team 2</label>
                </div>

                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Add New Team</label>
                </div>

              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-dark btn-ghost">Save</a>
                <a href className="btn btn-outline-dark btn-ghost" onClick={() => showTeamInfoEdit()}>Edit</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Beige Box */}

      </div>
      {/* /Main Container */}
      {/* ------------------------------- */}

    </>
  );
}

export default TeamInfoEdit;
