import { useForm } from "react-hook-form";

export const ModalTeamInfo = (props) => {
// export const ModalTeamInfo = ({ defaultValues, onFormSubmit, isLoading }, props) => {
// export const ModalTeamInfo = ({ defaultValues, onFormSubmit, isLoading, foo }) => {

// Destructure props so you don't have to define every argument in the function declaration
const {defaultValues, onFormSubmit, isLoading, foo} = props
  //-------------------------------
  // Needed for the actual form
  const { register, handleSubmit } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data)
  })

  return (
      <>
            {/* Button trigger modal */}
            {/* Modal */}
            <form onSubmit={onSubmit}>

            <div className="modal" id="popup-edit-team-info" tabIndex={-1} role="dialog" aria-labelledby="alLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-xl" role="document" style={{maxWidth: 800}}>
                <div className="modal-content bg-yellow px-120 py-70" style={{}}>
                  {/* Modal Header */}
                  <div className="modal-header">
                    <a href="#" data-bs-dismiss="modal">
                        <img src={process.env.PUBLIC_URL + '/assets/images/Close.png'} className style={{width: 14, position: 'absolute', right: 30, top: 30}} />
                    </a>
                  </div>
                  {/* /Modal Header */}
                  {/* Modal Body */}
                  <div className="modal-body">
                    <div className="container px-60">
                      <div className="row mb-20">
                        <div className="col">
                          <h2 className="modal-title" id="popup-edit-team-info-label">Team Info</h2>
                            {foo}

                        </div>
                      </div>

                      <div className="row mb-20 text-small">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="name" className="text-bold text-small mb-2">Team Name*</label>

                            <input {...register("name", { required: true, minLength: 2 })}
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              id="name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mb-20 text-small">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="concept" className="text-bold text-small mb-2">Team Concept</label>

                            <textarea {...register("concept", { required: true, minLength: 2 })}
                              className="form-control"
                              placeholder="Team Concept"
                              rows={3}
                              id="concept"
                            />
                          </div>

                          <p className="mt-10">
                            Give us a reason to want you: ridiculous car, funny theme, dorky costumInevsit,eweird history, whatever.
                          </p>
                        </div>
                      </div>

                      <div className="row mb-20">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="hometown" className="text-bold text-small mb-2">Hometown*</label>

                            <input {...register("hometown", { required: true, minLength: 2 })}
                              type="text"
                              className="form-control"
                              placeholder="Hometown"
                              id="hometown"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mb-20">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="website" className="text-bold text-small mb-2">Website</label>
                            <input {...register("website", { required: false })}
                              type="text"
                              className="form-control"
                              placeholder="Website"
                              id="website"
                            />
                          </div>
                          <div className="form-group mt-20">

                            <input {...register("public_profile", { required: false })}
                              type="checkbox"
                              className="form-check-input me-10 checkbox-small"
                              id="public_profile"
                            />

                            <label htmlFor="public_profile" className="text-bold text-small mb-2">Check here if you want fans to see your info</label>
                          </div>
                          <div className="form-group mt-20">
                            <p className="text-small">
                              This site protected by reCAPCHA. The Google <a href="#" className="text-green-dark">Privacy Policy</a> and <a href="#" className="text-green-dark">Terms of Serivce</a> apply.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col text-center">
                          <input
                            type="submit"
                            value="Save"
                            className="btn btn-outline-dark btn-ghost"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* /Modal Body */}
                </div>
              </div>
            </div>
            </form>
            {/* /Modal */}
            {/* /Popup > Edit Team Info */}

         </>
  )
}
