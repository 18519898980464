import React from 'react';

export default function StaticRaceEntryAddForm() {
    return (
    <>

      {/* This is important, make the main container always be px-0 */}
      {/* Main Container */}
      <div className="container px-0">
        {/* Hero Image */}
        {/* Hero Image */}
        <section className="d-none d-md-flex">
          <div className="container">
            <div className="row">
              <div className="col-sm p-0" id="banner-image" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/Banner-Race-Details.png'})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                minHeight: '500',
                height: '100%',
                backgroundSize: 'cover'
              }}>

              </div>
            </div>
          </div>
        </section>
        {/* /Hero Image */}
        {/* Hero Image > Mobile Version */}
        <section className="d-md-none">
          <div className="container">
            <div className="row">
              <div className="col-sm p-0" id="banner-image" style={{

                backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/Banner-Race-Details.png'})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                minHeight: '500',
                height: '100%',
                backgroundSize: 'cover'

                }}>
              </div>
            </div>
          </div>
        </section>
        {/* /Hero Image > Mobile Version */}
        {/* /Hero Image */}

        <img src={process.env.PUBLIC_URL + '/assets/images/Banner-Race-Details.png'} />

        {/* Main Header Text */}
        <div className="container">
          {/* Banner Header */}
          <div className="container text-center py-90 ">
            <div className="row my-2">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <h1 className="text-center p-4">Create Entry</h1>
              </div>
              {/* Location */}
              <h4 className="text-center font-weight-bold pb-1" />
              {/* Dates / Deadline */}
              <div className="font-weight-bold pb-1">
                -  / Entry Deadline:   			</div>
              {/* Title */}
              <div className="pb-3">
              </div>
            </div>
          </div>
          {/* /Banner Header */}
        </div>
        {/* /Main Header Text */}

        {/* Beige Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40">Team Info</h1>
                <h2 className="text-center mb-30" style={{}}>Add Your Team</h2>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 1</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 2</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 3</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 4</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Enter New Team</label>
                </div>
              </div>
            </div>

            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-dark btn-ghost">Save</a>
                <a href className="btn btn-outline-dark btn-ghost">Edit</a>
              </div>
            </div>

          </div>
        </section>
        {/* /Beige Box */}

        {/* Beige Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">

            <h1 className="text-center mb-50">Team Info</h1>

            <div className="row">

              {/* Left Column */}
              <div className="col bor0">
                <h2 className="mb-30">Add Your Team</h2>

                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 1</label>
                </div>

                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 2</label>
                </div>

                <div className="mt-40">
                  <a href className="btn btn-outline-dark btn-ghost">Save</a>
                  <a href className="btn btn-outline-dark btn-ghost">Edit</a>
                </div>

                <div className="">
                  <a href className="btn btn-outline-dark btn-ghost px-75">Kill This Team For Eternity*</a>
                </div>

                <div>
                  <p className="text-small py-20 pe-120">
                    *This team entry will be gone forever and you will never, ever, ever be able to get it back!
                  </p>
                </div>

              </div>
              {/* /Left Column */}

              {/* Right Column */}
                <div className="col ps-60 bor0" style={{borderLeft: '1px solid black'}}>
                <h2 className="mb-30">Team Details</h2>

                <div className="mb-20">
                  <p className="text-small">
                    Enter/confirm your team info below. And don’t stint on the Team Concept-the better it is, the more likely you are to get in.
                  </p>
                </div>

                <div className="row mb-20 text-small">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">Team Name*</label>
                      <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                    </div>
                  </div>
                </div>

                <div className="row mb-20 text-small">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">Team Concept</label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
                    </div>
                    <p className="mt-10">
                      Give us a reason to want you: ridiculous car, funny theme, dorky costumInevsit,eweird history, whatever.
                    </p>
                  </div>
                </div>

                <div className="row mb-20">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">Hometown*</label>
                      <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                    </div>
                  </div>
                </div>

                <div className="row mb-20">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="first_last_name" className="text-bold text-small mb-2">Website</label>
                      <input type="email" className="form-control" id="first_last_name" aria-describedby="emailHelp" placeholder />
                    </div>
                    <div className="form-group mt-20">
                      <input className="form-check-input me-10 checkbox-small" type="checkbox" defaultValue id="test-checkbox" />
                      <label htmlFor="test-checkbox" className="text-bold text-small mb-2">Check here if you want fans to see your info</label>
                    </div>
                    <div className="form-group mt-20">
                      <p className="text-small">
                        This site protected by reCAPCHA. The Google <a href="#" className="text-green-dark">Privacy Policy</a> and <a href="#" className="text-green-dark">Terms of Serivce</a> apply.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
              {/* /Right Column */}

            </div>
          </div>
        </section>
        {/* /Beige Box */}

        {/* Yellow Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-yellow mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40">Event Info</h1>
                <h2 className="text-center mb-30" style={{}}>Car for this Entry</h2>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 1</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 2</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 3</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 4</label>
                </div>
                <h2 className="text-center mt-40 mb-30" style={{}}>Options for this Entry</h2>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Camping $50</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Rent Transponder</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Garage $150</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Test Day $150</label>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-dark btn-ghost">Save</a>
                <a href className="btn btn-outline-dark btn-ghost">Edit</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Yellow Box */}
        {/* Black Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-black mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40 text-yellow">Fee Info</h1>
                <h2 className="text-center mb-30 text-yellow" style={{}}>Summary</h2>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Team
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $1,550
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Camping
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Rent Transponder
                  </div>
                  <div className="col-2 text-gray text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Garage
                  </div>
                  <div className="col-2 text-gray text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Test Day
                  </div>
                  <div className="col-2 text-gray text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Shiatsu Massage
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Total Fees
                  </div>
                  <div className="col-2 text-yellow text-bold text-uppercase text-end">
                    $1,650
                  </div>
                </div>
                <h2 className="text-center mt-50 mb-30 text-yellow" style={{}}>Payment History</h2>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Total Fees Received
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $1,650
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Balance Due
                  </div>
                  <div className="col-2 text-red text-bold text-uppercase text-end">
                    $1,600
                  </div>
                </div>
                <h2 className="text-center mt-60 mb-30 text-yellow" style={{}}>Payment Deadline</h2>
                <div className="row mb-20">
                  <div className="col-12 text-white text-bold text-uppercase text-center">
                    xx/xx/xxxx
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-white btn-ghost">Pay Now</a>
                <a href className="btn btn-outline-white btn-ghost">Pay Later</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Black Box */}
      </div>
      {/* /Main Container */}

      </>
    );
  }
