import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { getMyTeams2, createTeam } from "../services/api"

import { TeamForm2 } from "../components/TeamForm2"

import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants'

function Test(){

  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useMutation(createTeam)

  const onFormSubmit = async (data) => {
    await mutateAsync({...data})
    // navigate("/")
  }

  // Add the member ID to the default form values when creating a new team
  // NOTE: Other options: https://react-hook-form.com/api/useform/
  const data = {
    fk_captain_id: MEMBER_ID,
  }

  return (
    <div className='container'>
      <section>
        <div className="bg-beige p-50">

    	    <h1>Test</h1>
          {MEMBER_ID}

          <TeamForm2 defaultValues={data} onFormSubmit={onFormSubmit} isLoading={isLoading}/>

        </div>
      </section>
    </div>
  )
}

export default Test
