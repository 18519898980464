import React, {useState} from 'react'
import { ModalTeamInfo } from './ModalTeamInfo';
import { useQuery, useMutation } from 'react-query'
import { createTeam } from "../services/api"
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants'

// export const TeamForm2 = ({ defaultValues, onFormSubmit, isLoading }) => {
export const TeamInfo = (props) => {

  const [team_id, setTeamId] = useState('')

  const handleClickSave = (e) => {
    // console.log(e.target.dataset.teamId)
    alert("Save: " + team_id)
  }

  const handleClickEdit = (e) => {
    // console.log(e.target.dataset.teamId)
    // alert("Edit")
    alert("Edit: " + team_id)
  }

  const handleChange2 = (e) => {
    // Here, we invoke the callback with the new value
    props.onChange(e.target.value)
    setTeamId(e.target.value)
  }

  //-------------------------------
  // For ModalTeamInfo Component
  const { mutateAsync, isLoading } = useMutation(createTeam)
  const onFormSubmit = async (data) => {
    await mutateAsync({...data})
  }
  // Add the member ID to the default form values when creating a new team
  // NOTE: Other options: https://react-hook-form.com/api/useform/
  const data = {
    fk_captain_id: MEMBER_ID,
  }

  return (
    <>
      {/* Beige Box */}
      <section>

        <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">
          <div className="row">
            <div className="col col-lg-6 offset-lg-3">
              <h1 className="text-center mb-40">Team Info</h1>
              <h2 className="text-center mb-30">Add Your Team</h2>

              {team_id}

              <ModalTeamInfo
                defaultValues={data}
                onFormSubmit={onFormSubmit}
                isLoading={isLoading}
                foo="bar"
              />

              <div className="form-group mt-20 mb-20">

                <input
                  className="form-check-input me-20"
                  type="radio"
                  name="team_id"
                  id="team-id-13060"
                  value="13060"
                  onChange={handleChange2}
                />

                <label htmlFor="team-id-13060" className="text-bold text-uppercase mb-1 mt-2">Team 1</label>
              </div>

              <div className="form-group mt-20 mb-20">

                <input
                  className="form-check-input me-20"
                  type="radio"
                  name="team_id"
                  id="team-id-13275"
                  value="13275"
                  onChange={handleChange2}
                />

                <label htmlFor="team-id-13275" className="text-bold text-uppercase mb-1 mt-2">Team 2</label>
              </div>

              <div className="form-group mt-20 mb-20">

                <input
                  className="form-check-input me-20"
                  type="radio"
                  name="team_id"
                  id="team-id-new"
                  value=""
                  onChange={handleChange2}
                />

                <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Enter New Team</label>
              </div>

            </div>
          </div>

          <div className="row mt-20">
            <div className="col col-lg-6 offset-lg-3 text-center">
              <a className="btn btn-outline-dark btn-ghost" onClick={handleClickSave}>Save</a>
              <a className="btn btn-outline-dark btn-ghost" onClick={handleClickEdit}>Edit</a>

              <a href="#" className="btn btn-outline-dark btn-ghost" data-bs-toggle="modal" data-bs-target="#popup-edit-team-info">Edit</a>
            </div>
          </div>

        </div>
      </section>
      {/* /Beige Box */}
    </>
  )
}
