import create from 'zustand';
import { devtools } from "zustand/middleware";
import pipe from 'ramda.pipe'

// define the store
/*
const useStore = create(set => ({
  votes: 0,
  addVotes: () => set(state => ({ votes: state.votes + 1 })),
  subtractVotes: () => set(state => ({ votes: state.votes - 1 })),

}));
*/

/*
const useStore = create(

  devtools(set => ({
    votes: 0,
    addVotes: () => set(state => ({ votes: state.votes + 1 })),
    subtractVotes: () => set(state => ({ votes: state.votes - 1 })),
  }))

);
*/

// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.log('  applying', args)
      set(...args)
      console.log('  new state', get())
    },
    get,
    api
  )

const createStore = pipe(log, devtools, create)

const useStore = createStore(set => ({
  votes: 0,
  addVotes: () => set(state => ({ votes: state.votes + 1 })),
  subtractVotes: () => set(state => ({ votes: state.votes - 1 })),
}));

export default useStore;
