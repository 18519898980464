import React from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'

async function fetchPosts(){
	const {data} = await axios.get('https://jsonplaceholder.typicode.com/posts/')
  return data
}

function MultipleQueries(){
  const {data, error, isError, isLoading } = useQuery('posts', fetchPosts)

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className='container'>
    	<h1>Posts</h1>
    	{
        data.map((post, index) => {
        	return (
						<>
							<h4 key={index}>{post.title}</h4>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</p>
						</>
					)
        })
    	}
    </div>
  )
}

export default MultipleQueries;
