import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import pipe from 'ramda.pipe'

// Option 1:
/*
const useFishStore = create(
  persist(
    (set, get) => ({
      fishes: 0,
      addAFish: () => set({ fishes: get().fishes + 1 }),
    }),
    {
      name: 'food-storage', // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
)
*/

// Option 2:
/*
const useFishStore = create(
  devtools(
    persist(
      (set, get) => ({
        fishes: 0,
        addAFish: () => set({ fishes: get().fishes + 1 }),
      }),
      {
        name: 'food-storage', // unique name
        getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
      }
    )
  )
)
*/

// Option 3:
/*
const myMiddlewares = (f) => devtools(persist(f))

const useFishStore = create(
    myMiddlewares(
      (set, get) => ({
        fishes: 0,
        addAFish: () => set({ fishes: get().fishes + 1 }),
      }),
      {
        name: 'food-storage', // unique name
        getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
      }
    )
)

console.log('hello');
*/

// Option 4:
/*
// const createStore = pipe(devtools, persist, create)
const createStore = pipe(persist, devtools, create)

const useFishStore = createStore(
  (set, get) => ({
    fishes: 0,
    addAFish: () => set({ fishes: get().fishes + 1 }),
  }),
  {
    name: 'food-storage', // unique name
    getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
  }

)
*/

// Option 5:
/*
const fishStore = (set, get) => ({
  fishes: 0,
  addAFish: () => set({ fishes: get().fishes + 1 }),
});

const useFishStore = create(
  devtools(
    persist(fishStore, {
      name: 'food-storage',
      getStorage: () => sessionStorage,
    }),
  ),
);
*/

// Option 6:
// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.log('  applying', args)
      set(...args)
      console.log('  new state', get())
    },
    get,
    api
  )

const createStore = pipe(log, devtools, persist, create)

const useFishStore = createStore(
  (set, get) => ({
    fishes: 0,
    addAFish: () => set({ fishes: get().fishes + 1 }),
  }),
  {
    name: 'food-storage', // unique name
    getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
  }

)

export default useFishStore;
