// src/components/Member.js
import React from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
// import {MEMBER_ID} from '../constants/Constants';
import HeroText from '../components/HeroText';
import ListRacesWhite from '../components/ListRacesWhite';
import ListRacesBeige from '../components/ListRacesBeige';
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS} from '../constants'

const url = `${URL_MEMBERS}/${MEMBER_ID}`;
// const url = 'https://jsonplaceholder.typicode.com/posts/1';

async function fetchMember(){
	const {data} = await axios.get(url);
  return data;
}

function RenderName({data}) {
  return (
    <>
      <div>
        <h1>{data.first_name} {data.last_name}</h1>
      </div>
    </>
  );
}

function Home() {

  const {data, error, isError, isLoading, isFetching } = useQuery(['member', MEMBER_ID], fetchMember);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      {/* Main Container */}
      <div className="container px-0">

        <h1>Hello</h1>
          <h1>{MEMBER_ID}</h1>
          <h1>{url}</h1>

        {/* <HeroText data={data[0]} activeButton="home" /> */}

        {/* ------------------------------- */}
        {/* Events You've Entered */}
        <section className="mb-20">
          <div className="container px-30 py-10 px-lg-70 py-lg-20 bg-yellow">
            <div className="row">
              <div className="col text-uppercase text-bold text-center">
                Events You've Entered
              </div>
            </div>
          </div>
        </section>
        {/* /Events You've Entered */}
        {/* ------------------------------- */}

        {/* ------------------------------- */}
        {/* Table Header Black*/}
        <section>
          <div className="container mb-20">
            {/* ------------------------------- */}
            {/* Desktop Version */}
            <div className="row bg-black px-70 py-20 d-none d-md-flex">
              <div className="col-sm-3 col-lg-3 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Location</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Date</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Team</h4>
              </div>
              <div className="col-sm-3 col-lg-1 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Car</h4>
              </div>
              <div className="col-sm-3 col-lg-2 my-auto ">
                <h4 className="text-white text-uppercase font-weight-bold">Status</h4>
              </div>
            </div>
            {/* /Desktop Version */}
            {/* ------------------------------- */}

            {/* ------------------------------- */}
            {/* TODO: */}
            {/* Mobile Version */}
            <div className="row bg-black px-30 py-20 d-md-none">
              <div className="col-6 my-auto">
                <h4 className="text-white text-uppercase font-weight-bold">Location</h4>
              </div>
              <div className="col-6 my-auto">
                <h4 className="text-white text-uppercase font-weight-bold">Event/Date</h4>
              </div>
            </div>
            {/* /Mobile Version */}
            {/* ------------------------------- */}

          </div>
        </section>
        {/* /Table Header Black*/}
        {/* ------------------------------- */}

        <ListRacesBeige />

        {/* ------------------------------- */}
        {/* Other Events You Might Want to Enter */}
        <section className="mb-20">
          <div className="container px-30 py-10 px-lg-70 py-lg-20 bg-black">
            <div className="row">
              <div className="col text-uppercase text-bold text-center text-white">
                Other Events You Might Want to Enter
              </div>
            </div>
          </div>
        </section>
        {/* Other Events You Might Want to Enter */}
        {/* ------------------------------- */}

        <ListRacesWhite />

      </div>
      {/* /Main Container */}
      {/* ------------------------------- */}

    </>
  );
}

export default Home;
