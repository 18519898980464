import React, {useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import axios from 'axios'
import {MEMBER_ID, URL_MEMBERS, URL_TEAMS, URL_RACES, URL_SIGNUPS, URL_API_ROOT} from '../constants'
import { useParams } from 'react-router-dom';

// URLs
// const url = `http://localhost:8080/members/${MEMBER_ID}`;
// const url_member = `http://localhost:8080/members`;
// const url_signup = `http://localhost:8080/signups`;
// const url_race = `http://localhost:8080/races`;
// const url_team = `http://localhost:8080/teams`;

const url = `${URL_MEMBERS}/${MEMBER_ID}`;
const url_member = `${URL_MEMBERS}`;
const url_signup = `${URL_SIGNUPS}`;
const url_race = `${URL_RACES}`;
const url_team = `${URL_TEAMS}`;

// Fetch Queries
async function fetchRace(someId){
  const {data} = await axios.get(url_race + '/' + someId);
  // NOTE: This is so that I don't have to use data_race[0] all over the place...
  return data[0];
}

async function fetchMyTeams(someId){
  const {data} = await axios.get(url_member + '/' + someId + '/teams');
  return data;
}

export default function RaceEntryAdd() {
  const { id } = useParams();

  // Race Data
  const {data: data_race, isLoading: isLoadingRace } = useQuery(['race', id], () => fetchRace(id));

  // My Teams Data
  const {data: data_my_teams, isLoading: isLoadingMyTeams } = useQuery(['my_teams', MEMBER_ID], () => fetchMyTeams(MEMBER_ID));

  // const isLoading =  isLoadingSignup || isLoadingRace || isLoadingTeam || isLoadingMember;
  const isLoading =  isLoadingRace || isLoadingMyTeams;
  if (isLoading) return "Loading...";

    return (
    <>

      {/* This is important, make the main container always be px-0 */}
      {/* Main Container */}
      <div className="container px-0">
        {/* Hero Image */}
        {/* Hero Image */}
        <section className="d-none d-md-flex">
          <div className="container">
            <div className="row">
              <div className="col-sm p-0" id="banner-image" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/Banner-Race-Details.png'})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                minHeight: '500',
                height: '100%',
                backgroundSize: 'cover'
              }}>

              </div>
            </div>
          </div>
        </section>
        {/* /Hero Image */}
        {/* Hero Image > Mobile Version */}
        <section className="d-md-none">
          <div className="container">
            <div className="row">
              <div className="col-sm p-0" id="banner-image" style={{

                backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/Banner-Race-Details.png'})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                minHeight: '500',
                height: '100%',
                backgroundSize: 'cover'

                }}>
              </div>
            </div>
          </div>
        </section>
        {/* /Hero Image > Mobile Version */}
        {/* /Hero Image */}

        <img src={process.env.PUBLIC_URL + '/assets/images/Banner-Race-Details.png'} />

        {/* Main Header Text */}
        <div className="container">
          {/* Banner Header */}
          <div className="container text-center py-90 ">
            <div className="row my-2">
              <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <h1 className="text-center p-4">Create Entry</h1>
              </div>

              {/* Location */}
              <h4 className="text-center font-weight-bold pb-1" >
                {data_race.venue}
              </h4>

              {/* Dates / Deadline */}
              <div className="font-weight-bold pb-1">
                {data_race.date_start} - {data_race.date_end}  / Entry Deadline: {data_race.entry_deadline}
              </div>

              {/* Title */}
              <div className="pb-3">
                {data_race.title}
              </div>

            </div>
          </div>
          {/* /Banner Header */}
        </div>
        {/* /Main Header Text */}

        {/* Beige Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-beige mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40">Team Info</h1>
                <h2 className="text-center mb-30" style={{}}>Add Your Team</h2>

                  {
                    data_my_teams.map((my_teams, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="form-group mt-20 mb-20">
                            <input className="form-check-input me-20" type="radio" name="team_id" value={my_teams.team_id} />
                            <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">
                              {my_teams.name}
                            </label>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }

                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="team_id" value="new" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Enter New Team</label>
                </div>

              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-dark btn-ghost">Save</a>
                <a href className="btn btn-outline-dark btn-ghost">Edit</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Beige Box */}

        {/* Yellow Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-yellow mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40">Event Info</h1>
                <h2 className="text-center mb-30" style={{}}>Car for this Entry</h2>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 1</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 2</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 3</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="radio" name="test-radio" id="test-radio1" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Existing Team 4</label>
                </div>
                <h2 className="text-center mt-40 mb-30" style={{}}>Options for this Entry</h2>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Camping $50</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Rent Transponder</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Garage $150</label>
                </div>
                <div className="form-group mt-20 mb-20">
                  <input className="form-check-input me-20" type="checkbox" defaultValue id="test-checkbox" />
                  <label htmlFor="first_last_name" className="text-bold text-uppercase mb-1 mt-2">Test Day $150</label>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-dark btn-ghost">Save</a>
                <a href className="btn btn-outline-dark btn-ghost">Edit</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Yellow Box */}
        {/* Black Box */}
        <section>
          <div className="container px-30 py-40 px-lg-70 py-lg-90 bg-black mb-20">
            <div className="row">
              <div className="col col-lg-6 offset-lg-3">
                <h1 className="text-center mb-40 text-yellow">Fee Info</h1>
                <h2 className="text-center mb-30 text-yellow" style={{}}>Summary</h2>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Team
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $1,550
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Camping
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Rent Transponder
                  </div>
                  <div className="col-2 text-gray text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Garage
                  </div>
                  <div className="col-2 text-gray text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Test Day
                  </div>
                  <div className="col-2 text-gray text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Shiatsu Massage
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $50
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Total Fees
                  </div>
                  <div className="col-2 text-yellow text-bold text-uppercase text-end">
                    $1,650
                  </div>
                </div>
                <h2 className="text-center mt-50 mb-30 text-yellow" style={{}}>Payment History</h2>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Total Fees Received
                  </div>
                  <div className="col-2 text-white text-bold text-uppercase text-end">
                    $1,650
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-10 text-white text-bold text-uppercase">
                    Balance Due
                  </div>
                  <div className="col-2 text-red text-bold text-uppercase text-end">
                    $1,600
                  </div>
                </div>
                <h2 className="text-center mt-60 mb-30 text-yellow" style={{}}>Payment Deadline</h2>
                <div className="row mb-20">
                  <div className="col-12 text-white text-bold text-uppercase text-center">
                    xx/xx/xxxx
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col col-lg-6 offset-lg-3 text-center">
                <a href className="btn btn-outline-white btn-ghost">Pay Now</a>
                <a href className="btn btn-outline-white btn-ghost">Pay Later</a>
              </div>
            </div>
          </div>
        </section>
        {/* /Black Box */}
      </div>
      {/* /Main Container */}

      </>
    );
  }
